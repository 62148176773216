import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import UpdateVersion from '../update-version'

const PageTitle = ({ title, action }) => (
  <>
    <Card square variant="pageTitle">
      <CardHeader
        title={title}
        action={action}
        variant="pageTitle"
        sx={{
          height: '42px',
          boxShadow: '0px 2px 2px -1px rgba(0,0,0,0.2)',
        }}
      />
    </Card>
    <UpdateVersion />
  </>
)

export default PageTitle
