import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { useGetMyCampaignQuery } from '../../../../services/campaigns/slice'
import { useGetMyProspectQuery } from '../../../prospects/slice'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'

import LoadingScreen from '../../../../components/loading-screen'
import PageTitle from '../../../../components/page-title'
import Sections from '../sections'
import Disposition from '../disposition'
import Contacts from '../contacts'
import Events from '../events'
import ConfirmationDialog from '../../../../components/confirmationDialog'

const Prospect = () => {
  const eventDrawer = useSelector((state) => state.eventDrawer)
  const { campaignId, prospectId } = useParams()
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)

  const { data: campaign, isFetching: isCampaignLoading } = useGetMyCampaignQuery({ campaignId }, { skip: !campaignId })
  const { data: prospect } = useGetMyProspectQuery({ prospectId, campaignId }, { skip: !prospectId || !campaignId })

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch({ type: 'eventDrawer/setCurrentCampaign', payload: {} })
      dispatch({ type: 'eventDrawer/setCurrentProspect', payload: {} })
      dispatch({ type: 'eventDrawer/close' })
    }
  }, [location, dispatch])

  const handleBack = () => {
    if (eventDrawer.isOpen) {
      setIsConfirmationDialogOpen(true)
    } else navigate(-1)
  }

  if (isCampaignLoading || !campaign || !prospect) return <LoadingScreen />
  return (
    <div>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
        <Toolbar />
        <PageTitle
          title={`Prospects :: ${campaign?.name || '...'} :: ${prospect?.Name || '...'} `}
          action={
            <Button onClick={handleBack} variant="contained">
              Back to List
            </Button>
          }
        />
        <Container maxWidth={false} padding={0} margin={0} disableGutters sx={{ background: '#EEE' }}>
          <Disposition campaign={campaign} prospect={prospect} />
        </Container>
      </AppBar>
      <Container
        maxWidth={false}
        padding={0}
        margin={0}
        disableGutters
        sx={{ paddingTop: '124px', background: '#EEE' }}
      >
        <ConfirmationDialog
          isOpen={isConfirmationDialogOpen}
          message={'If you continue, you will lose any unsaved changes to your Event.'}
          onClose={() => setIsConfirmationDialogOpen(false)}
          onConfirm={() => navigate(-1)}
        />
        <Sections campaign={campaign} prospect={prospect} />
        <Contacts campaign={campaign} prospect={prospect} />
        <Events campaign={campaign} prospect={prospect} />
      </Container>
    </div>
  )
}

export default Prospect
