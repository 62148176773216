const config = {
  app: {
    name: 'SALES',
    platform: 'sales',
    version: "1.2.9",
    hideAlertDelay: 10000,
  },
  environment: "prod",
  aws: {
    region: "ca-central-1",
    cognito: {
      clientId: "71nvtsh1eqrdssuv85atfn15j1",
      userPoolId: "ca-central-1_mlr1D04eO",
    },
  },
  api: {
    baseUrl: "https://api.leadcogrp.ca",
  },
  mui: {
    licenseKey: "ad331435b4fa4c9fece6894d5eb9d525Tz1sZWFkY28sRT0xOTI1MDA5OTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sS1Y9Mg==",
  },
  logRocket: {
    appId: "njvmrw/bdr-portal",
  },
}

export default config
