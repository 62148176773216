import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

import { useGetFieldsQuery } from '../../../../services/fields/slice'
import FormikControl from '../../../../components/formik-control'

const fieldTypeToControlMapping = [
  {
    type: 'Text',
    controlType: 'input',
  },
  {
    type: 'Multiline',
    controlType: 'textarea',
  },
  {
    type: 'Number',
    controlType: 'input',
  },
  {
    type: 'Date',
    controlType: 'date',
  },
  {
    type: 'Boolean',
    controlType: 'checkbox',
  },
  {
    type: 'Selection',
    controlType: 'select',
  },
  {
    type: 'Reference',
    controlType: 'select',
  },
]

const Field = ({ field }) => {
  const { data: fields } = useGetFieldsQuery(field.Entity?.toLowerCase())
  const [thisField, setThisField] = useState()
  const [selectOptions, setSelectOptions] = useState()

  useEffect(() => {
    if (fields) {
      const thisFieldDefinition = fields.find((f) => f?.['Field ID'] === field?.['Field ID'])
      const mergedField = { ...thisFieldDefinition, ...field }
      setThisField(mergedField)
      if (mergedField?.Type === 'Selection') {
        const options = mergedField?.['Options List']?.split(',').map((o) => ({ value: o.trim(), key: o.trim() }))
        setSelectOptions(options)
      }
    }
  }, [field, fields])

  const getControlType = (fieldType) => {
    const mapping = fieldTypeToControlMapping.find((m) => m.type === fieldType)
    if (mapping) return mapping.controlType
    return 'input'
  }

  if (!thisField) return null
  return (
    <>
      <Box width={200}>
        <Typography>{thisField?.Name}</Typography>
      </Box>
      <Box>
        <FormikControl
          control={getControlType(thisField?.Type) || 'input'}
          type={thisField?.Type}
          name={thisField?.['Field ID']}
          label={thisField?.Name}
          options={selectOptions}
          width={thisField?.Width}
          disabled={!!thisField?.['Read Only']}
        />
      </Box>
      <Box ml={1}>
        {thisField?.Description && (
          <Tooltip title={thisField.Description}>
            <HelpOutlineIcon color="primary" />
          </Tooltip>
        )}
      </Box>
    </>
  )
}

export default Field
