import LoadingButton from '@mui/lab/LoadingButton'
import { CustomBox, MuiWrapper } from './styled'

function DrawerActions({ buttons, ...muiBoxProps }) {
  return (
    <MuiWrapper component="footer" {...muiBoxProps}>
      <CustomBox>
        {buttons.map((button, index) => {
          return (
            <LoadingButton
              key={`${index.toString()}`}
              variant={button.variant}
              color={button?.color}
              disabled={button.disabled || false}
              onClick={button.action}
              loading={button.loading || false}
            >
              {button.label}
            </LoadingButton>
          )
        })}
      </CustomBox>
    </MuiWrapper>
  )
}

export default DrawerActions
