import Box from '@mui/material/Box'
import Logo from '../../assets/images/leadco.svg'

const BusinessLogo = () => {
  return (
    <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        component="img"
        src={Logo}
        sx={{
          height: '18px',
          maxWidth: 'none',
          mr: '6px',
        }}
        alt="LEADCO"
      />
    </Box>
  )
}

export default BusinessLogo
