import MuiBox from '@mui/material/Box'
import MuiContainer from '@mui/material/Container'
import { styled } from '@mui/material/styles'

export const CustomContainer = styled(MuiContainer, { label: 'CustomContainer', slot: 'Root' })(() => ({
  marginTop: 64, // Adjust this value to match the height of your AppBar
  height: 'calc(100vh - 64px)', // Adjust the height to account for the AppBar
  overflowY: 'auto',
}))

export const CustomPageBox = styled(MuiBox, { name: 'CustomPageBox', slot: 'Root' })(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}))

export const CustomOutletBox = styled(MuiBox, { name: 'OutletBox', slot: 'Root' })(() => ({
  flewGrow: 1,
  height: '100%',
  width: '100%',
  overflow: 'auto',
}))

export const CustomMiniDrawerContainer = styled(MuiBox, { name: 'CustomMiniDrawerContainer', slot: 'Root' })(() => ({
  display: 'flex',
  height: '100%',
  flewGrow: 1,
}))
