import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import CallIcon from '@mui/icons-material/Call'
import AddIcCallIcon from '@mui/icons-material/AddIcCall'
import PersonIcon from '@mui/icons-material/Person'

const NO_RESULTS_TEXT = 'No Results Found'

const SearchResult = ({ option }) => {
  const textEllipsisStyle = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    paddingRight: '4px',
  }

  if (option.Name === NO_RESULTS_TEXT) {
    return (
      <Grid container alignItems="center">
        <Grid item>
          <Typography color="grey">No Results Found</Typography>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid container alignItems="center" borderBottom={1} borderColor={'lightgrey'} paddingBottom={1}>
      <Grid item>
        {option.campaign?.name && (
          <Typography fontSize={12} color={'grey'} height={14}>
            Campaign: {option.campaign.name}{' '}
            {option.campaign['Assigned To User'] && `(${option.campaign['Assigned To User']})`}
          </Typography>
        )}
        <Typography fontWeight={600}>{option.Name}</Typography>
        {option?.['Alternate Company Name']?.trim() && <Typography>AKA: {option['Alternate Company Name']}</Typography>}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {option?.['Main Phone Number'] && (
            <>
              <CallIcon fontSize="small" color="primary" />
              <Typography>{option[`Main Phone Number`]}</Typography>
            </>
          )}
          {option?.['Secondary Phone Number'] && (
            <>
              <AddIcCallIcon fontSize="small" color="primary" />
              <Typography>{option[`Secondary Phone Number`]}</Typography>
            </>
          )}
        </Box>
        {option?.contacts?.map((contact, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <PersonIcon fontSize="12" color="primary" />
            {contact?.Name && (
              <Typography fontSize={12} sx={textEllipsisStyle}>
                {contact.Name}
              </Typography>
            )}
            {contact?.Email && (
              <Typography fontSize={12} sx={textEllipsisStyle}>
                ({contact.Email})
              </Typography>
            )}
            {contact?.['Phone Number'] && (
              <>
                <CallIcon fontSize="12" color="primary" />
                <Typography fontSize={12} sx={textEllipsisStyle}>
                  {contact['Phone Number']}
                </Typography>
              </>
            )}
            {contact?.['Mobile Number'] && (
              <>
                <AddIcCallIcon fontSize="12" color="primary" />
                <Typography fontSize={12} sx={textEllipsisStyle}>
                  {contact['Mobile Number']}
                </Typography>
              </>
            )}
          </Box>
        ))}
      </Grid>
    </Grid>
  )
}

export default SearchResult
