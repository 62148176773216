import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const MuiWrapper = styled(Box, { name: 'DrawerContent', slot: 'Root' })(({ theme }) => ({
  padding: theme.spacing(2, 2, 0, 2),
  flexGrow: 1,
  overflow: 'auto',
}))

export default MuiWrapper
