import Input from './components/input.jsx'
import Checkbox from './components/checkbox.jsx'
import Select from './components/select.jsx'
import TextArea from './components/textarea.jsx'
import DatePicker from './components/datepicker.jsx'
import DateTimePicker from './components/datetimepicker.jsx'
import PhoneNumberInput from './components/phonenumberinput.jsx'
import StarRating from './components/starrating.jsx'

const FormikControl = (props) => {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'textarea':
      return <TextArea {...rest} />
    case 'select':
      return <Select {...rest} />
    // case 'radio':
    case 'checkbox':
      return <Checkbox {...rest} />
    case 'date':
      return <DatePicker {...rest} />
    case 'datetime':
      return <DateTimePicker {...rest} />
    case 'phonenumber':
      return <PhoneNumberInput {...rest} />
    case 'rating':
      return <StarRating {...rest} />
    default:
      return null
  }
}

export default FormikControl
