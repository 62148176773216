import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'

const Rating = ({ rating }) => {
  // if rating is 0, return only 1 empty star
  // otherwise return the number of filled stars based on the rating
  return (
    <>
      {rating === undefined || rating === 0 ? (
        <StarOutlineOutlinedIcon fontSize={'10'} sx={{ color: 'lightgrey' }} />
      ) : (
        Array.from({ length: rating }, (_, i) => (
          <StarOutlinedIcon key={`star-${i}`} fontSize={'10'} sx={{ color: 'orange' }} />
        ))
      )}
    </>
  )
}

export default Rating
