import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
// This is the landing page of the app
// This should be redirected to the dashboard by the router

const Home = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/dashboard')
  }, [navigate])

  return null
}

export default Home
