import { useNavigate } from 'react-router'
import { ROUTES } from '../../../constants/routes'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

const CampaignItem = ({ campaign }) => {
  const navigate = useNavigate()

  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        '&.MuiAccordion-root': {
          margin: 0,
        },
        '&.MuiCollapse-root': {
          padding: 0,
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            marginLeft: 1,
            marginBottom: 0,
          },
        }}
      >
        <Box>
          <Typography
            onClick={() => navigate(`${ROUTES.CAMPAIGNS}/${campaign.id}/prospects`)}
            sx={{ cursor: 'pointer', fontWeight: '500' }}
          >
            {campaign.name}
          </Typography>
          <Typography fontSize={12} marginBottom={1}>
            Active: <b>{campaign?.statistics?.prospects?.active || 0}</b> New:{' '}
            <b>{campaign?.statistics?.prospects?.new || 0}</b> Pipeline:{' '}
            <b>{campaign?.statistics?.prospects?.delayed || 0}</b> Meetings:{' '}
            <b>{campaign?.statistics?.prospects?.meeting || 0}</b> Complete:{' '}
            <b>{campaign?.statistics?.prospects?.hidden}</b>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        <ul style={{ margin: 0 }}>
          {[...campaign?.programs]
            ?.sort((a, b) =>
              (a?.name === 'None' ? '0000' : a?.name).localeCompare(b?.name === 'None' ? '0000' : b?.name)
            )
            ?.filter((program) => (program?.statistics?.active || 0) + (program?.statistics?.new || 0) > 0)
            ?.map((program) => (
              <li key={program.name}>
                <Typography
                  fontSize={13}
                  onClick={() =>
                    navigate(
                      `${ROUTES.CAMPAIGNS}/${campaign.id}/programs/${encodeURIComponent(program.name)}/prospects`
                    )
                  }
                  sx={{
                    display: 'inline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                  }}
                >
                  Program: <b>{program.name}</b> (Active: <b>{program?.statistics?.active || 0}</b> New:{' '}
                  <b>{program?.statistics?.new || 0}</b> Pipeline: <b>{program?.statistics?.delayed || 0}</b> Meetings:{' '}
                  <b>{program?.statistics?.meeting || 0}</b> Complete: <b>{program?.statistics?.hidden}</b>)
                </Typography>
              </li>
            ))}
        </ul>
        <ul style={{ margin: 0 }}>
          {[...campaign?.producers]
            ?.sort((a, b) =>
              (a?.name === 'None' ? '0000' : a?.name).localeCompare(b?.name === 'None' ? '0000' : b?.name)
            )
            ?.filter((producer) => (producer?.statistics?.active || 0) + (producer?.statistics?.new || 0) > 0)
            ?.map((producer) => (
              <li key={producer.name}>
                <Typography
                  fontSize={13}
                  onClick={() =>
                    navigate(
                      `${ROUTES.CAMPAIGNS}/${campaign.id}/producers/${encodeURIComponent(producer.name)}/prospects`
                    )
                  }
                  sx={{
                    display: 'inline',
                    cursor: 'pointer',
                    '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                  }}
                >
                  Producer: <b>{producer.name}</b> (Active: <b>{producer?.statistics?.active || 0}</b> New:{' '}
                  <b>{producer?.statistics?.new || 0}</b> Pipeline: <b>{producer?.statistics?.delayed || 0}</b>{' '}
                  Meetings: <b>{producer?.statistics?.meeting || 0}</b> Complete: <b>{producer?.statistics?.hidden}</b>)
                </Typography>
              </li>
            ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  )
}

export default CampaignItem
