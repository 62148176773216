import apiSlice from '../api/slice'
import { TAGS } from '../../constants/tags'

const eventTypesSlice = apiSlice.injectEndpoints({
  name: 'eventTypes',
  endpoints: (builder) => ({
    getEventTypes: builder.query({
      query: () => ({ url: `/v2/events/types`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.EVENTTYPES],
    }),
  }),
})

export const { useGetEventTypesQuery } = eventTypesSlice

export default eventTypesSlice
