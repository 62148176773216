import MuiDrawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'

export const miniDrawerHeight = 180

const openedMixin = (theme) => ({
  height: miniDrawerHeight,
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowY: 'hidden',
  boxShadow: '0px -5px 7px -3px rgba(0,0,0,0.2)',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('height', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overFlowY: 'hidden',
  // height: `calc(${theme.spacing(7)} + 1px)`,
  height: 0,
})

export const CustomDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: miniDrawerHeight,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export const CustomDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.grey[200],
  borderTop: `2px solid ${theme.palette.grey[400]}`,
  borderBottom: `2px solid ${theme.palette.grey[400]}`,
}))
