import Typography from '@mui/material/Typography'
import { formatDate } from '../../../utils/dates'
import { Box } from '@mui/material'
import Rating from './rating'

const CampaignProspectListItem = ({ prospect, handleNavigate }) => {
  return (
    <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography
          component="span"
          onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
          sx={{
            display: 'inline',
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
          }}
        >
          {formatDate(prospect?.dueDate) || 'Immediately'} - <b>{prospect.Name}</b> - {prospect.campaign.name} -{' '}
          {prospect.campaign?.Disposition?.trim() ?? 'New'} -
        </Typography>
        <Rating rating={prospect.campaign?.Rating} />
        {prospect.campaign?.Reason && <Typography> - {prospect.campaign?.Reason}</Typography>}
      </Box>
    </li>
  )
}

export default CampaignProspectListItem
