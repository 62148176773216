import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import Typography from '@mui/material/Typography'

const ConfirmNavigation = ({ blocker }) => {
  return (
    <Dialog open={blocker?.state === 'blocked'}>
      <DialogTitle>
        <Typography fontWeight={600}>You have unsaved changes</Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <ReportProblemIcon color="error" sx={{ mr: 2 }} />
          <DialogContentText>If you continue, your changes will be lost.</DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="error" variant="contained" autoFocus onClick={() => blocker.reset?.()}>
          Cancel
        </Button>
        <Button onClick={() => blocker.proceed?.()}>Continue</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmNavigation
