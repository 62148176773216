import { useDispatch, useSelector } from 'react-redux'
import { clearAlert } from './slice'

import SnackBar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import AppConfig from '../../config'

const AppAlert = () => {
  const { showAlert, severity, message } = useSelector((state) => state.alert)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(clearAlert())
  }

  return (
    <SnackBar
      open={showAlert}
      autoHideDuration={AppConfig.app.hideAlertDelay}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </SnackBar>
  )
}

export default AppAlert
