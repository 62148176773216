import apiSlice from '../api/slice'
import { TAGS } from '../../constants/tags'

const dispositionsSlice = apiSlice.injectEndpoints({
  name: 'dispositions',
  endpoints: (builder) => ({
    getCampaignDispositions: builder.query({
      query: ({ campaignId }) => ({ url: `/v2/dispositions/campaigns/${campaignId}`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.DISPOSITIONS],
    }),
  }),
})

export const { useGetCampaignDispositionsQuery } = dispositionsSlice

export default dispositionsSlice
