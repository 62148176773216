import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import AppBar from '../../components/app-bar'
import AppAlert from '../app-alert'
import AppConfig from '../../config'
import EventDrawer from '../event-drawer'

import { CustomOutletBox, CustomPageBox, CustomContainer, CustomMiniDrawerContainer } from './styled'

const Layout = () => {
  const { user } = useSelector((state) => state.persisted.auth)

  useEffect(() => {
    if (AppConfig.environment.toLowerCase() !== 'prod')
      document.title = `[${AppConfig.environment.slice(0, 3).toUpperCase()}] LEADCO::SALES`
  }, [])

  return (
    <CustomContainer maxWidth={false} disableGutters fixed={false} id="container-root">
      <AppAlert />
      <CustomPageBox component="div">
        <AppBar />
        <CustomMiniDrawerContainer>
          <CustomOutletBox component="div">
            <Outlet />
          </CustomOutletBox>
        </CustomMiniDrawerContainer>
      </CustomPageBox>
      {user && <EventDrawer />}
    </CustomContainer>
  )
}

export default Layout
