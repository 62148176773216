import { useGetRequiredVersionQuery } from '../../services/version/slice'
import AppConfig from '../../config'
import Versions from '../../utils/versions'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

const UpdateVersion = () => {
  const { data: requiredVersion } = useGetRequiredVersionQuery({}, { pollingInterval: 5 * 60 * 1000 })

  const handleUpdate = () => {
    window.location.reload(true)
  }

  if (requiredVersion && Versions.isOutdated(AppConfig.app.version, requiredVersion.version))
    return (
      <Alert severity="warning">
        A new version of the application is available. Please update to the latest version.
        <Button onClick={handleUpdate} size="small" color="warning" variant="outlined" sx={{ p: 0, ml: 2 }}>
          Update
        </Button>
      </Alert>
    )
  return null
}

export default UpdateVersion
