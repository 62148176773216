import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import BusinessLogo from '../business-logo'
import { ROUTES } from '../../constants/routes'
import AppConfig from '../../config'

import SearchBar from '../search-bar'

import { impersonate, unimpersonate } from '../../services/auth/slice'
import { useGetUsersQuery } from '../../services/users/slice'
import { useGetRequiredVersionQuery } from '../../services/version/slice'
import Versions from '../../utils/versions'

import apiSlice from '../../services/api/slice'

const environmentTag =
  AppConfig.environment.toUpperCase() !== 'PROD' ? `[${AppConfig.environment.substring(0, 3).toUpperCase()}]` : ''

const LgiAppBar = () => {
  const { user, impersonation } = useSelector((state) => state.persisted.auth)
  const { data: requiredVersion } = useGetRequiredVersionQuery(
    {},
    { skip: !user?.email, pollingInterval: 5 * 60 * 1000 }
  )
  const { data: users } = useGetUsersQuery({}, { skip: !user?.email })
  const [anchorEl, setAnchorEl] = useState(null)
  const [subAnchorEl, setSubAnchorEl] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isOutdated, setIsOutdated] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (users && user) {
      const admin = users.find((u) => u.email === user.email)?.isAdmin
      setIsAdmin(admin)
    }
  }, [users, user])

  useEffect(() => {
    if (requiredVersion) {
      setIsOutdated(Versions.isOutdated(AppConfig.app.version, requiredVersion.version))
    }
  }, [requiredVersion])

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleImpersonateMenu = (event) => {
    setSubAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    navigate(ROUTES.LOGOUT)
  }

  const handleLocalRefresh = async () => {
    await apiSlice.util.resetApiState()
    await new Promise((resolve) => setTimeout(resolve, 500)).then(() => window.location.reload(false))
    handleClose()
  }

  const handleImpersonate = async ({ user }) => {
    await dispatch(impersonate({ email: user.email, given_name: user.givenName, family_name: user.familyName }))
    setSubAnchorEl(null)
    handleLocalRefresh()
  }

  const handleUnimpersonate = async () => {
    await dispatch(unimpersonate())
    navigate(ROUTES.DASHBOARD)
    handleLocalRefresh()
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}>
        <Toolbar>
          <Link to="/">
            <BusinessLogo />
          </Link>
          <Typography variant="h1" ml="0.5rem" noWrap>
            :: {AppConfig.app.name}
          </Typography>
          <Typography variant="h1" color="error" sx={{ flexGrow: 1, marginLeft: 1 }}>
            {environmentTag}
          </Typography>
          {user && (
            <>
              <Box sx={{ flexGrow: 1 }} />
              <SearchBar />
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color={impersonation ? 'error' : 'primary'}
                sx={{
                  justifyContent: 'flex-end',
                  textTransform: 'none',
                }}
                startIcon={<AccountCircleIcon />}
                onClick={handleMenu}
              >
                <Typography variant="h2" noWrap>
                  {impersonation
                    ? `${impersonation.given_name} ${impersonation.family_name}`
                    : `${user?.given_name} ${user?.family_name}`}
                </Typography>
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                // keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem>
                  <Typography color={isOutdated ? 'error' : 'inherit'}>Version: {AppConfig.app.version}</Typography>
                </MenuItem>
                {requiredVersion && <MenuItem>Required: {requiredVersion.version}</MenuItem>}
                {impersonation && <MenuItem onClick={handleUnimpersonate}>Unimpersonate</MenuItem>}
                {isAdmin && users && <MenuItem onClick={handleImpersonateMenu}>Impersonate</MenuItem>}
                <Menu
                  id="menu-impersonate"
                  anchorEl={subAnchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  open={Boolean(subAnchorEl)}
                  onClose={() => setSubAnchorEl(null)}
                >
                  {users
                    ?.filter((u) => u.email !== user.email)
                    ?.filter((u) => u.email !== impersonation?.email)
                    .map((u) => (
                      <MenuItem key={u.email} onClick={() => handleImpersonate({ user: u })}>
                        {u.givenName} {u.familyName}
                      </MenuItem>
                    ))}
                </Menu>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default LgiAppBar
