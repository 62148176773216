import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { ROUTES } from '../constants/routes'

const ProtectedRoutes = () => {
  const { user } = useSelector((state) => state.persisted?.auth)
  return user ? <Outlet /> : <Navigate to={ROUTES.LOGIN} replace />
}

export default ProtectedRoutes
