import apiSlice from '../../services/api/slice'

const usersSlice = apiSlice.injectEndpoints({
  name: 'users',
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => ({ url: '/v2/users' }),
      transformResponse: (response) => response.data.map((user) => ({ ...user, id: user.sub })),
      providesTags: ['Users'],
    }),
  }),
})

export const { useGetUsersQuery } = usersSlice

export default usersSlice
