import { useNavigate } from 'react-router'
import { ROUTES } from '../../../constants/routes'

import { convertToDate, formatDate } from '../../../utils/dates'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'

import MeetingItemBookedStatus from './meetingItemBookedStatus'
import Rating from './rating'

const MeetingItem = ({ prospects, open = false, title }) => {
  const navigate = useNavigate()

  const handleNavigate = (campaignId, prospectId) => {
    navigate(`${ROUTES.CAMPAIGNS}/${campaignId}/prospects/${prospectId}`)
  }

  return (
    <Accordion
      disableGutters
      elevation={0}
      defaultExpanded={open}
      sx={{
        '&.MuiAccordion-root': {
          margin: 0,
        },
        '&.MuiCollapse-root': {
          padding: 0,
          margin: 0,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        sx={{
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
          '& .MuiAccordionSummary-content': {
            marginLeft: 1,
            marginBottom: 0,
            marginTop: 0,
          },
        }}
      >
        <Box>
          <Typography sx={{ cursor: 'pointer', fontWeight: '500' }}>{`${title}: (${
            prospects?.length || 0
          } items)`}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingTop: 0 }}>
        {prospects.length === 0 ? (
          <Typography marginLeft={2} color={'grey'}>
            No items
          </Typography>
        ) : (
          <ul>
            {prospects.map((prospect) => (
              <li key={`${prospect['Prospect ID']}-${prospect.campaign?.id}`}>
                <Box display={'flex'} alignItems={'center'}>
                  <Typography
                    component="span"
                    onClick={() => handleNavigate(prospect.campaign?.id, prospect['Prospect ID'])}
                    sx={{
                      display: 'inline',
                      cursor: 'pointer',
                      '&:hover': { textDecoration: 'underline', textDecorationColor: 'grey' },
                    }}
                  >
                    {formatDate(convertToDate(prospect.campaign?.['Next Action Date']))} - <b>{prospect.Name}</b> -{' '}
                    {prospect.campaign?.name} - {prospect.campaign?.Disposition} -
                  </Typography>
                  <Rating rating={prospect.campaign?.Rating} />
                  {prospect.campaign?.Reason && <Typography> - {prospect.campaign?.Reason}</Typography>}
                </Box>
                {prospect.event && <MeetingItemBookedStatus prospect={prospect} />}
              </li>
            ))}
          </ul>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default MeetingItem
