import { createTheme } from '@mui/material/styles'

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#8BCF24',
      dark: '#57cf24',
      light: '#aafa32',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Arial", sans-serif',
    htmlFontSize: 16,
    fontSize: 12,
    h1: {
      // AppBar title
      fontSize: '1.5rem',
      fontWeight: 400,
      letterSpacing: '0.1rem',
    },
    h2: {
      // AppBar user
      fontSize: '1rem',
      fontWeight: 500,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          backgroundColor: 'white',
        },
      },
      defaultProps: {
        position: 'fixed',
        color: 'inherit',
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'pageTitle' },
          style: {
            padding: '16px',
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'pageTitle' },
          style: {
            // borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: '#f5f5f5',
            padding: '4px',
          },
        },
      ],
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          display: 'flex', // Use flexbox
          alignItems: 'center', // Vertically center children
          padding: '0 16px 0 16px',
          // padding: '0 16px 0 16px',
          // alignItems: 'baseline',
        },
        title: {
          fontWeight: 400,
          fontSize: '1.25rem',
          textTransform: 'uppercase',
          flexGrow: 1,
        },
        action: {
          margin: '0 0 0 16px',
          padding: 0,
        },
      },
    },
  },
})

export default customTheme
