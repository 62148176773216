function isOutdated(installedVersion, requiredVersion) {
  const parseVersion = (version) => version.split('.').map(Number)

  const [installedMajor, installedMinor, installedPatch] = parseVersion(installedVersion)
  const [currentMajor, currentMinor, currentPatch] = parseVersion(requiredVersion)

  if (currentMajor > installedMajor) return true
  if (currentMajor < installedMajor) return false

  if (currentMinor > installedMinor) return true
  if (currentMinor < installedMinor) return false

  if (currentPatch > installedPatch) return true
  return false
}

module.exports = {
  isOutdated,
}
