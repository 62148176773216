import { Field } from 'formik'
import Rating from '@mui/material/Rating'

const StarRating = (props) => {
  const { name, width, ...rest } = props
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return <Rating max={5} {...rest} {...field} />
      }}
    </Field>
  )
}

export default StarRating
