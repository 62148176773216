import { Field } from 'formik'
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

const Checkbox = (props) => {
  const { name, ...rest } = props
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <FormControl sx={{ my: 1 }}>
            <FormControlLabel
              control={<MuiCheckbox {...rest} {...field} checked={field.value} size="small" />}
              label={rest.label}
              value={field.value}
              labelPlacement="end"
            />
            <FormHelperText>{meta.error}</FormHelperText>
          </FormControl>
        )
      }}
    </Field>
  )
}

export default Checkbox
