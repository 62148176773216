import { Field } from 'formik'
import MuiFormControl from '@mui/material/FormControl'
import MuiInputLabel from '@mui/material/InputLabel'
import MuiMenuItem from '@mui/material/MenuItem'
import MuiSelect from '@mui/material/Select'

const Select = (props) => {
  const { name, width, label, options, ...rest } = props
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <MuiFormControl sx={{ my: 1, minWidth: width }} error={meta.touched && !!meta.error}>
            <MuiInputLabel id={name} size="small">
              {label}
            </MuiInputLabel>
            <MuiSelect labelId={name} label={label} {...field} {...rest} size="small">
              {options.map((option) => (
                <MuiMenuItem key={option.value} value={option.value}>
                  {option.key}
                </MuiMenuItem>
              ))}
            </MuiSelect>
          </MuiFormControl>
        )
      }}
    </Field>
  )
}

export default Select
