// React
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ProtectedRoutes from './protectedRoutes'

// Components
import AppLayout from '../components/app-layout'
import Dashboard from '../features/dashboard'
import Home from '../features/home'
import Login from '../features/login'
import Logout from '../features/logout'
import Prospects from '../features/prospects'
import Prospect from '../features/prospect'
import Timesheet from '../features/timesheet'

import { ROUTES } from '../constants/routes'

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: '/',
          element: <ProtectedRoutes />,
          children: [
            {
              path: ROUTES.HOME,
              element: <Home />,
            },
            {
              path: ROUTES.DASHBOARD,
              element: <Dashboard />,
            },
            {
              path: ROUTES.PROSPECTS,
              element: <Prospects />,
            },
            {
              path: ROUTES.CAMPAIGNS.concat('/:campaignId/prospects/:prospectId'),
              element: <Prospect />,
            },
            {
              path: ROUTES.CAMPAIGNS.concat('/:campaignId/prospects'),
              element: <Prospects />,
            },
            {
              path: ROUTES.CAMPAIGNS.concat('/:campaignId/programs/:programName/prospects'),
              element: <Prospects />,
            },
            {
              path: ROUTES.CAMPAIGNS.concat('/:campaignId/producers/:producerName/prospects'),
              element: <Prospects />,
            },
            {
              path: ROUTES.TIMESHEET,
              element: <Timesheet />,
            },
          ],
        },
        {
          path: ROUTES.LOGIN,
          element: <Login />,
        },
        {
          path: ROUTES.LOGOUT,
          element: <Logout />,
        },
      ],
    },
  ])

  return <RouterProvider router={router} />
}

export default AppRouter
