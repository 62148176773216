import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

import { useGetCampaignDispositionsQuery } from '../../../../services/dispositions/slice'
import { convertToDate, formatDate } from '../../../../utils/dates'

const isValidDate = (dateString) => {
  const date = convertToDate(dateString)
  return date instanceof Date && !isNaN(date)
}

const Disposition = ({ campaign, prospect }) => {
  const { data: dispositions } = useGetCampaignDispositionsQuery({ campaignId: campaign?.id }, { skip: !campaign?.id })

  const [thisDisposition, setThisDisposition] = useState({})

  useEffect(() => {
    if (prospect?.campaign?.['Disposition']?.trim()?.length === 0) {
      setThisDisposition({ name: 'None', valid: false })
    } else if (dispositions) {
      const found = dispositions.find((disposition) => disposition.Name === prospect?.campaign?.['Disposition'])
      if (found) {
        setThisDisposition({ name: found.Name, valid: true })
      } else {
        setThisDisposition({ name: prospect?.campaign?.['Disposition']?.trim(), valid: false })
      }
    }
  }, [dispositions, prospect])

  return (
    <Paper>
      <Box p={2} m={0} display="flex" flexDirection="row" alignItems="center" borderColor={'darkgrey'}>
        <Typography fontSize={18}>DISPOSITION</Typography>
        <Typography ml={2} fontSize={18} fontWeight="bold" color={thisDisposition.valid ? 'inherit' : 'error'}>
          {thisDisposition.name}
        </Typography>
        <Typography ml={4} fontSize={18}>
          NEXT ACTION
        </Typography>
        <Typography
          ml={2}
          fontSize={18}
          fontWeight={prospect?.campaign?.['Next Action Date'] ? 'bold' : 'normal'}
          color={
            prospect?.campaign?.['Next Action Date']
              ? isValidDate(prospect?.campaign?.['Next Action Date'])
                ? convertToDate(prospect?.campaign?.['Next Action Date']) < new Date()
                  ? 'error'
                  : 'black'
                : 'error'
              : 'grey'
          }
        >
          {isValidDate(prospect?.campaign?.['Next Action Date'])
            ? formatDate(convertToDate(prospect?.campaign?.['Next Action Date']))
            : prospect?.campaign?.['Next Action Date'] !== ''
            ? `Invalid (${prospect?.campaign?.['Next Action Date'] ?? 'None'})`
            : 'None'}
        </Typography>
        <Typography ml={4} fontSize={18}>
          PRODUCER
        </Typography>
        <Typography
          ml={2}
          fontSize={18}
          fontWeight={prospect?.campaign?.Producer?.trim()?.length !== 0 ? 'bold' : 'normal'}
          color={prospect?.campaign?.Producer?.trim()?.length !== 0 ? 'inherit' : 'grey'}
        >
          {prospect?.campaign?.Producer?.trim() || 'None'}
        </Typography>
        <Typography ml={4} fontSize={18}>
          PROGRAM
        </Typography>
        <Typography
          ml={2}
          fontSize={18}
          fontWeight={prospect?.campaign?.Program?.trim()?.length !== 0 ? 'bold' : 'normal'}
          color={prospect?.campaign?.Program?.trim()?.length !== 0 ? 'inherit' : 'grey'}
        >
          {prospect?.campaign?.Program?.trim() || 'None'}
        </Typography>
      </Box>
    </Paper>
  )
}

export default Disposition
