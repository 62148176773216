import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Stack, Button, Typography, IconButton } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh'

import PageTitle from '../../components/page-title'
import TimesheetDateSelector from './components/dateSelector'
import TimesheetChart from './components/timesheetChart'

import { useGetMySegmentsQuery } from '../../services/statistics/slice'

const Timesheet = () => {
  const navigate = useNavigate()

  const stateTimesheetStartDate = useSelector((state) => state.app.timesheet.startDate)
  const stateTimesheetEndDate = useSelector((state) => state.app.timesheet.endDate)
  const timesheetStartDate = useMemo(
    () => (stateTimesheetStartDate ? new Date(stateTimesheetStartDate) : null),
    [stateTimesheetStartDate]
  )
  const timesheetEndDate = useMemo(
    () => (stateTimesheetEndDate ? new Date(stateTimesheetEndDate) : null),
    [stateTimesheetEndDate]
  )

  const {
    data: segments,
    isLoading,
    isFetching,
    refetch,
  } = useGetMySegmentsQuery(
    {
      from: timesheetStartDate ? timesheetStartDate.toISOString().split('T')[0] : '',
      to: timesheetEndDate ? timesheetEndDate.toISOString().split('T')[0] : '',
    },
    { skip: !timesheetStartDate || !timesheetEndDate }
  )

  const handleRefreshClick = () => {
    refetch()
  }

  return (
    <>
      <PageTitle
        title={
          <Stack direction="row" spacing={0} alignItems={'center'} display={'flex'}>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 400,
              }}
            >
              Timesheet
            </Typography>
            <IconButton onClick={handleRefreshClick}>
              <RefreshIcon sx={{ color: 'lightgrey' }} />
            </IconButton>
          </Stack>
        }
        action={
          <Stack direction="row" spacing={1} alignItems={'center'}>
            <TimesheetDateSelector name="From (start of)" type="start" />
            <TimesheetDateSelector name="To (end of)" type="end" />
            <Button
              onClick={() => navigate(-1)}
              variant="contained"
              color="primary"
              size="small"
              sx={{ height: '2rem' }}
            >
              Back
            </Button>
          </Stack>
        }
      />
      <TimesheetChart segments={segments} isLoading={isLoading || isFetching} />
    </>
  )
}

export default Timesheet
