import { Box, Typography } from '@mui/material'

const DailyChartLabels = () => {
  return (
    <Box mt={1} width="100%" display="flex" sx={{ borderBottom: '1px solid lightgrey' }}>
      <Box width="11%">
        <Typography width="100%" fontWeight={500} align={'center'}>
          Date
        </Typography>
      </Box>
      <Box width="75%">
        <Typography width="100%" fontWeight={500} align={'center'}>
          Activity
        </Typography>
      </Box>
      <Box width="7%">
        <Typography width="100%" fontWeight={500} align={'center'}>
          Active
        </Typography>
      </Box>
      <Box width="7%">
        <Typography width="100%" fontWeight={500} align={'center'}>
          Break
        </Typography>
      </Box>
    </Box>
  )
}

export default DailyChartLabels
