import { useEffect, useState } from 'react'
import { useGetMyCampaignsQuery } from '../../../services/campaigns/slice'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import CampaignItem from './campaignItem'

const Campaigns = () => {
  const { data: rawCampaigns, isLoading } = useGetMyCampaignsQuery()

  const [campaigns, setCampaigns] = useState([])
  useEffect(() => {
    if (rawCampaigns) {
      setCampaigns(rawCampaigns)
    }
  }, [rawCampaigns])

  return (
    <Box margin={3}>
      <Typography>MY CAMPAIGNS</Typography>
      {isLoading ? (
        <Typography marginLeft={2} color={'grey'}>
          Loading...
        </Typography>
      ) : campaigns?.length ? (
        campaigns.map((campaign) => <CampaignItem key={campaign.id} campaign={campaign} />)
      ) : (
        <Typography marginLeft={2} color={'grey'}>
          No campaigns available.
        </Typography>
      )}
    </Box>
  )
}

export default Campaigns
