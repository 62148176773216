import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent, { timelineContentClasses } from '@mui/lab/TimelineContent'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineDot from '@mui/lab/TimelineDot'

import Event from './event'

import { useGetEventsQuery } from '../../../../services/events/slice'
import { convertToDate } from '../../../../utils/dates'

const EventList = ({ campaign, prospect }) => {
  const { data: events, isFetching } = useGetEventsQuery(
    {
      campaignId: campaign.id,
      prospectId: prospect['Prospect ID'],
    },
    { skip: !campaign.id || !prospect['Prospect ID'] }
  )

  const [convertedEvents, setConvertedEvents] = React.useState([])
  React.useEffect(() => {
    if (events) {
      const convertedAndSortedEvents = events.map((thisEvent) => ({
        ...thisEvent,
        'Created Date': convertToDate(thisEvent['Created Date']),
        'Is Pinned': thisEvent?.['Is Pinned']?.toLowerCase() === 'true',
      }))
      convertedAndSortedEvents.sort((a, b) => b['Created Date'] - a['Created Date'])
      convertedAndSortedEvents.sort((a, b) => b['Is Pinned'] - a['Is Pinned'])
      setConvertedEvents(convertedAndSortedEvents)
    }
  }, [events])

  if (isFetching) {
    return (
      <Box paddingX={2} paddingBottom={2}>
        <Typography>Loading...</Typography>
      </Box>
    )
  }

  if (convertedEvents.length === 0) {
    return (
      <Box paddingX={2} paddingBottom={2}>
        <Typography sx={{ color: 'grey' }}>No events logged.</Typography>
      </Box>
    )
  }

  return (
    <Timeline
      position="right"
      sx={{
        [`& .${timelineContentClasses.root}`]: {
          flex: 0.1,
        },
      }}
    >
      {convertedEvents?.map((event, index) => {
        return (
          <TimelineItem key={event['Event ID']}>
            <TimelineContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                minWidth: '200px',
              }}
            >
              <Typography align="right">
                {convertToDate(event['Created Date']).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </Typography>
            </TimelineContent>
            <TimelineSeparator>
              <TimelineConnector />
              <TimelineDot variant={index > 0 ? 'outlined' : 'filled'} color="primary" />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineOppositeContent sx={{ py: '12px', px: 2 }}>
              <Event event={event} />
            </TimelineOppositeContent>
          </TimelineItem>
        )
      })}
    </Timeline>
  )
}

export default EventList
