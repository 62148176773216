import MuiCircularProgress from '@mui/material/CircularProgress'
import MuiBox from '@mui/material/Box'

function LoadingScreen({ width = '100vw', height = '100vh' }) {
  return (
    <MuiBox
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height,
        width,
      }}
    >
      <MuiCircularProgress />
    </MuiBox>
  )
}

export default LoadingScreen
