import { Field } from 'formik'
import TextField from '@mui/material/TextField'

const Input = (props) => {
  const { name, width, ...rest } = props
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <TextField
            error={!!meta.touched && !!meta.error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            size="small"
            sx={{
              my: 1,
              width,
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: 'inherit',
                color: 'black',
              },
              '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: 'whitesmoke',
              },
            }}
            {...rest}
            {...field}
          />
        )
      }}
    </Field>
  )
}

export default Input
