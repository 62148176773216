import apiSlice from '../../services/api/slice'
import { TAGS } from '../../constants/tags'

const eventsSlice = apiSlice.injectEndpoints({
  name: 'events',
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({ prospectId, campaignId }) => ({ url: `/v2/events/prospects/${prospectId}/campaigns/${campaignId}` }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.EVENTS],
    }),
    addEvent: builder.mutation({
      query: ({ prospectId, campaignId, event }) => ({
        url: `/v2/events/prospects/${prospectId}/campaigns/${campaignId}`,
        method: 'POST',
        body: event,
      }),
      invalidatesTags: [TAGS.EVENTS, TAGS.PROSPECT, TAGS.PROSPECTS],
    }),
    pinEvent: builder.mutation({
      query: ({ eventId, pinState }) => ({
        url: `/v2/events/${eventId}/pin/${pinState}`,
        method: 'PUT',
      }),
      invalidatesTags: [TAGS.EVENTS],
    }),
  }),
})

export const { useGetEventsQuery, useAddEventMutation, usePinEventMutation } = eventsSlice

export default eventsSlice
