import PushPinIcon from '@mui/icons-material/PushPin'
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined'

const EventPin = ({ isPinned, onPin }) => {
  if (isPinned) {
    return (
      <PushPinIcon
        color="primary"
        sx={{
          ml: '0.2rem',
          fontSize: '1rem',
          opacity: 1,
          transform: 'rotate(45deg)',
          '&:hover': {
            color: 'grey',
            opacity: 1,
            cursor: 'pointer',
          },
        }}
        onClick={() => onPin(false)}
      />
    )
  } else {
    return (
      <PushPinOutlinedIcon
        color="grey"
        sx={{
          ml: '0.2rem',
          fontSize: '1rem',
          opacity: 0.3,
          transform: 'rotate(45deg)',
          '&:hover': {
            color: 'primary.main',
            opacity: 1,
            cursor: 'pointer',
          },
        }}
        onClick={() => onPin(true)}
      />
    )
  }
}

export default EventPin
