import { useState } from 'react'
import ProspectContext from './context'

const ProspectContextProvider = ({ children }) => {
  const [data, setData] = useState({})
  const value = { data, setData }

  return <ProspectContext.Provider value={value}>{children}</ProspectContext.Provider>
}

export default ProspectContextProvider
