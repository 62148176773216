import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import { createAlert } from '../app-alert/slice'

const ResultDialog = ({ open, setOpen }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    setOpen(null)
  }

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    dispatch(
      createAlert({
        message: 'Prospect ID copied to clipboard',
        severity: 'success',
      })
    )
  }

  return (
    <Dialog open={!!open} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{'Prospect Information'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The selected prospect is not assigned to any of your campaigns.
          <br /> <br />
          <Box component="span">
            Prospect ID: <b>{open}</b>
            <ContentCopyOutlinedIcon onClick={() => copyToClipboard(open)} sx={{ height: '14px', cursor: 'pointer' }} />
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ResultDialog
