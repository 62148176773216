import { useMemo, useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import MuiLinearProgress from '@mui/material/LinearProgress'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { LicenseInfo } from '@mui/x-license-pro'

import { useGetCampaignProspectsQuery } from './slice'
import { useGetCampaignFieldsQuery } from '../../services/fields/slice'
import { useGetMyCampaignQuery } from '../../services/campaigns/slice'
import { useGetCampaignDispositionsQuery } from '../../services/dispositions/slice'

import AppConfig from '../../config'
import PageTitle from '../../components/page-title'
import { ROUTES } from '../../constants/routes'
import { convertToDate, formatDate } from '../../utils/dates'

import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined'
import StarOutlinedIcon from '@mui/icons-material/StarOutlined'

LicenseInfo.setLicenseKey(AppConfig.mui.licenseKey)

const Rating = ({ rating }) => {
  // if rating is 0, return only 1 empty star
  // otherwise return the number of filled stars based on the rating
  return (
    <>
      {rating === undefined || rating === 0 ? (
        <StarOutlineOutlinedIcon fontSize={'10'} sx={{ color: 'lightgrey' }} />
      ) : (
        Array.from({ length: rating }, (_, i) => (
          <StarOutlinedIcon key={`star-${i}`} fontSize={'10'} sx={{ color: 'orange' }} />
        ))
      )}
    </>
  )
}

const Prospects = () => {
  const { campaignId, programName, producerName } = useParams()
  const decodedProgramName = programName ? decodeURIComponent(programName) : null
  const decodedProducerName = producerName ? decodeURIComponent(producerName) : null

  const navigate = useNavigate()

  const { data: prospects, isLoading: isProspectsLoading } = useGetCampaignProspectsQuery({ campaignId })
  const { data: fields, isLoading: isFieldsLoading } = useGetCampaignFieldsQuery({ campaignId })
  const { data: campaign, isLoading: isCampaignLoading } = useGetMyCampaignQuery({ campaignId })
  const { data: dispositions, isLoading: isDispositionsLoading } = useGetCampaignDispositionsQuery({ campaignId })

  const [rowsAreSet, setRowsAreSet] = useState(false)
  const [rows, setRows] = useState([])
  const [gridPage, setGridPage] = useState(JSON.parse(sessionStorage.getItem(`page_${campaignId}`)) || {})
  const [gridFilterModel, setGridFilterModel] = useState(
    JSON.parse(sessionStorage.getItem(`filter_${campaignId}`)) || {
      items: [],
      logicOperator: 'and',
      quickFilterValues: [],
      quickFilterLogicOperator: 'and',
    }
  )
  const [gridSortModel, setGridSortModel] = useState(
    JSON.parse(sessionStorage.getItem(`sort_${campaignId}`)) || [{ field: 'campaignNextActionDate', sort: 'asc' }]
  )
  const apiRef = useGridApiRef()

  useEffect(() => {
    const handleRowsSet = (params) => {
      console.log('Data changed:', params, 'count', apiRef.current.getRowsCount())

      // Additional logic for handling data change
      const savedPaginationModel = sessionStorage.getItem(`page_${campaignId}`)
      console.log('savedPaginationModel', savedPaginationModel)
      const savedSortModel = sessionStorage.getItem(`sort_${campaignId}`)
      console.log('savedSortModel', savedSortModel)
      const savedFilterModel = sessionStorage.getItem(`filter_${campaignId}`)
      console.log('savedFilterModel', savedFilterModel)

      if (savedPaginationModel) {
        const paginationModel = JSON.parse(savedPaginationModel)
        apiRef.current.setPage(paginationModel.page ?? 0)
        setGridPage(JSON.parse(savedPaginationModel))
      }
      if (savedSortModel) {
        const sortModel = JSON.parse(savedSortModel)
        apiRef.current.setSortModel(sortModel)
        setGridSortModel(sortModel)
      }
      if (savedFilterModel) {
        const filterModel = JSON.parse(savedFilterModel)
        apiRef.current.setFilterModel(filterModel)
        setGridFilterModel(filterModel)
      }
      setRowsAreSet(true)
    }

    // Subscribe to the 'rowsSet' event
    apiRef.current.subscribeEvent('rowsSet', handleRowsSet)
  }, [apiRef, campaignId])

  useEffect(() => {
    if (gridPage?.page !== undefined) sessionStorage.setItem(`page_${campaignId}`, JSON.stringify(gridPage))
    else sessionStorage.removeItem(`page_${campaignId}`)
  }, [gridPage, campaignId])

  useEffect(() => {
    if (gridSortModel?.length > 0) sessionStorage.setItem(`sort_${campaignId}`, JSON.stringify(gridSortModel))
    else sessionStorage.removeItem(`sort_${campaignId}`)
  }, [gridSortModel, campaignId])

  useEffect(() => {
    if (gridFilterModel?.items?.length > 0)
      sessionStorage.setItem(`filter_${campaignId}`, JSON.stringify(gridFilterModel))
    else sessionStorage.removeItem(`filter_${campaignId}`)
  }, [gridFilterModel, campaignId])

  const onPaginationModelChange = (params) => {
    console.log('onPaginationModelChange', params)
    if (rowsAreSet) {
      console.log('Rows are set. Setting page:', params)
      setGridPage(params)
    } else {
      console.log('Rows are not set yet')
    }
  }

  const onFilterModelChange = (params) => {
    console.log('onFilterModelChange', params)
    if (rowsAreSet) {
      console.log('Rows are set. Setting filter:', params)
      setGridFilterModel(params)
    } else {
      console.log('Rows are not set yet')
    }
  }

  useEffect(() => {
    if (prospects && fields && dispositions) {
      const displayedProspects = prospects.map((prospect) => {
        const date2DaysAgo = new Date()
        date2DaysAgo.setDate(date2DaysAgo.getDate() - 2)
        const dateIn30Days = new Date()
        dateIn30Days.setDate(dateIn30Days.getDate() + 30)
        const dispositionLabel = prospect.campaign.Disposition
        const thisDisposition = dispositions.find((disposition) => disposition.Name === dispositionLabel)
        const nextActionDate = convertToDate(prospect.campaign?.['Next Action Date'])
        let hidden = thisDisposition?.['Is Hidden'] === 'TRUE'
        if (!hidden) {
          hidden =
            thisDisposition?.['Is Meeting'] === 'TRUE' && nextActionDate !== null && nextActionDate < date2DaysAgo
        }
        if (!hidden) {
          hidden =
            thisDisposition?.['Is Delayed'] === 'TRUE' && nextActionDate !== null && nextActionDate > dateIn30Days
        }
        if (!hidden && decodedProgramName) {
          hidden = (prospect.campaign?.Program || 'None') !== decodedProgramName
        }
        if (!hidden && decodedProducerName) {
          hidden = (prospect.campaign?.Producer || 'None') !== decodedProducerName
        }

        return {
          ...prospect,
          campaignDisposition: dispositionLabel,
          campaignNextActionDate: prospect.campaign?.['Next Action Date'],
          campaignProducer: prospect.campaign?.Producer,
          id: prospect['Prospect ID'],
          prospectId: prospect['Prospect ID'],
          hidden,
          unknownDisposition: !thisDisposition,
          campaignDispositionReason: prospect.campaign?.Reason,
          campaignRating: prospect.campaign?.Rating,
        }
      })
      const displayedProspectsFiltered = displayedProspects.filter((prospect) => !prospect.hidden)
      setRows(displayedProspectsFiltered)
    }
  }, [prospects, fields, dispositions, decodedProgramName, decodedProducerName])

  const columns = useMemo(() => {
    if (prospects && fields) {
      const visibleFields = fields.filter((field) => field?.['Grid Priority'])
      const prospectFields = visibleFields.map((prospectField) => {
        const type =
          prospectField.Type === 'boolean' ? 'boolean' : prospectField.Type === 'date' ? 'dateTime' : undefined
        return {
          field: prospectField['Field ID'],
          headerName: prospectField.Name,
          description: prospectField.Description,
          width: prospectField.Width || 200,
          editable: false,
          type,
          priority: prospectField?.['Grid Priority'] || 1000,
          valueGetter: (params) => {
            if (params.colDef.type === 'dateTime' && params.value) return convertToDate(params.value)
            return params.value
          },
        }
      })
      const sortedFields = prospectFields.sort((a, b) => a.priority - b.priority)
      sortedFields.unshift(
        {
          field: 'campaignDisposition',
          headerName: 'Disposition',
          width: 180,
          renderCell: (params) => {
            if (params.row.unknownDisposition) {
              return <div style={{ fontWeight: 450, color: 'red' }}>{params.value}</div>
            }
            return params.value
          },
        },
        {
          field: 'campaignDispositionReason',
          headerName: 'Reason',
          width: 120,
        },
        {
          field: 'campaignRating',
          headerName: 'Rating',
          width: 100,
          renderCell: (params) => {
            return <Rating rating={params.value} />
          },
        },
        {
          field: 'campaignNextActionDate',
          headerName: 'Next Action',
          width: 200,
          type: 'dateTime',
          valueGetter: (params) => {
            if (params.value) return convertToDate(params.value)
            return null
          },
          renderCell: (params) => {
            if (!params.value) return null
            const isPast = convertToDate(params.value) < new Date()
            return <div style={{ color: isPast ? 'red' : 'inherit' }}>{formatDate(convertToDate(params.value))}</div>
          },
        }
      )
      sortedFields.push({
        field: 'campaignProducer',
        headerName: 'Producer',
        width: 200,
      })

      return sortedFields
    }
    return []
  }, [fields, prospects])

  const onRowDoubleClick = (params, event) => {
    navigate(`${ROUTES.CAMPAIGNS}/${campaignId}/prospects/${params.row.prospectId}`)
  }

  const backToDashboard = () => {
    navigate(ROUTES.DASHBOARD)
  }

  let title = `Prospects :: ${campaign?.name || '...'}`
  if (decodedProgramName) {
    if (decodedProgramName === 'None') {
      title += ' :: No Program'
    } else {
      title += ` :: ${decodedProgramName}`
    }
  }
  if (decodedProducerName) {
    if (decodedProducerName === 'None') {
      title += ' :: No Producer'
    } else {
      title += ` :: ${decodedProducerName}`
    }
  }

  return (
    <>
      <PageTitle
        title={title}
        action={
          <Button onClick={backToDashboard} variant="contained">
            Back to Dashboard
          </Button>
        }
      />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          height: 'calc(100% - 60px)',
          width: '100%',
          paddingLeft: 1,
          paddingRight: 1,
          paddingTop: 1,
        }}
      >
        <DataGridPro
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          density="compact"
          loading={isProspectsLoading || isFieldsLoading || isCampaignLoading || isDispositionsLoading}
          onRowDoubleClick={onRowDoubleClick}
          onSortModelChange={setGridSortModel}
          sortModel={gridSortModel}
          autoPageSize
          pagination
          onPaginationModelChange={onPaginationModelChange}
          onFilterModelChange={onFilterModelChange}
          filterModel={gridFilterModel}
          slots={{
            loadingOverlay: MuiLinearProgress,
          }}
          sx={{
            '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
              outline: 'none !important',
            },
            '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
              outline: 'none !important',
            },
          }}
        />
      </Container>
    </>
  )
}

export default Prospects
