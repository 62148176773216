// import { useTheme } from "@mui/material"
import MuiDrawer from '@mui/material/Drawer'
import DrawerActions from './components/actions'
import DrawerContainer from './components/container'
import DrawerHeader from './components/header'
import DrawerContent from './components/content'
import MuiToolbar from '@mui/material/Toolbar'

function RightDrawer({
  children,
  showDrawer,
  setShowDrawer,
  anchor = 'right',
  keepMounted,
  preventClosing,
  variant = 'temporary',
}) {
  return (
    <MuiDrawer
      anchor={anchor}
      open={showDrawer}
      onClose={() => {
        if (!preventClosing) {
          setShowDrawer(false)
        }
      }}
      variant={variant}
      ModalProps={{
        keepMounted,
      }}
      // sx={{ zIndex: theme.zIndex.drawer + 2 }}
    >
      <MuiToolbar sx={{ width: '400px' }} />
      {children}
    </MuiDrawer>
  )
}

export { RightDrawer, DrawerActions, DrawerContainer, DrawerContent, DrawerHeader }
