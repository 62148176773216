import { useEffect, useState } from 'react'
import { convertToDate } from '../../../utils/dates'

import { useGetMyFutureMeetingsQuery } from '../../prospects/slice'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import MeetingItem from './meetingItem'

const Overdue = () => {
  const { data: prospects, isLoading: isProspectsLoading } = useGetMyFutureMeetingsQuery()
  const [convertedProspects, setConvertedProspects] = useState([])
  const DAYSINFUTURE = 7

  useEffect(() => {
    if (prospects) {
      const now = new Date()
      const todayEnd = new Date(now.setHours(0, 0, 0, 0))
      const nearFuture = new Date(todayEnd.setDate(todayEnd.getDate() + DAYSINFUTURE))

      setConvertedProspects(
        prospects?.upcoming
          .map((prospect) => ({
            ...prospect,
            dueDate: convertToDate(prospect.campaign?.['Next Action Date']),
            nearFuture: convertToDate(prospect.campaign?.['Next Action Date']) <= nearFuture,
          }))
          .sort((a, b) => a.dueDate - b.dueDate)
      )
    }
  }, [prospects])

  return (
    <>
      <Box margin={3}>
        <Typography>MY MEETINGS</Typography>
        {isProspectsLoading ? (
          <Typography marginLeft={2} color={'grey'}>
            Loading...
          </Typography>
        ) : convertedProspects?.length > 0 ? (
          <>
            <MeetingItem key={'booked'} prospects={prospects.booked} open={false} title={`Booked this week`} />
            <MeetingItem
              key={'nearFuture'}
              prospects={convertedProspects.filter((prospect) => prospect.nearFuture)}
              open={convertedProspects.filter((prospect) => prospect.nearFuture).length > 0}
              title={`Within ${DAYSINFUTURE} days`}
            />
            <MeetingItem
              key={'future'}
              prospects={convertedProspects.filter((prospect) => !prospect.nearFuture)}
              open={false}
              title={`After ${DAYSINFUTURE} days`}
            />
          </>
        ) : (
          <Typography marginLeft={2} color={'grey'}>
            No meetings scheduled.
          </Typography>
        )}
      </Box>
    </>
  )
}

export default Overdue
