import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

import EventList from './eventList'

const Events = ({ campaign, prospect }) => {
  const dispatch = useDispatch()

  const onAddEventClick = () => {
    dispatch({ type: 'eventDrawer/setCurrentCampaign', payload: campaign })
    dispatch({ type: 'eventDrawer/setCurrentProspect', payload: prospect })
    dispatch({ type: 'eventDrawer/toggle' })
  }

  return (
    <Box px={2} pb={1}>
      <Paper>
        <Box display="flex" alignItems="center" py={2} margin={2}>
          <Typography pt={'2px'} fontSize={18}>
            EVENTS
          </Typography>
          <IconButton size="small" color="primary" onClick={onAddEventClick}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
        <EventList campaign={campaign} prospect={prospect} />
      </Paper>
    </Box>
  )
}

export default Events
