export const ROUTES = {
  DASHBOARD: '/dashboard',
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGGEDOUT: '/loggedout',
  PROSPECTS: '/prospects',
  CAMPAIGNS: '/campaigns',
  TIMESHEET: '/timesheet',
}
