import apiSlice from '../../services/api/slice'
import { TAGS } from '../../constants/tags'

const prospectsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyProspect: builder.query({
      query: ({ prospectId, campaignId }) => ({
        url: `/v2/prospects/${prospectId}/campaigns/${campaignId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.PROSPECT],
    }),
    getCampaignProspects: builder.query({
      query: ({ campaignId }) => ({ url: `/v2/prospects/campaigns/${campaignId}`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.PROSPECTS],
    }),
    getMyProspects: builder.query({
      query: () => ({ url: `/v2/prospects`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.PROSPECTS],
    }),
    getMyDueProspects: builder.query({
      query: () => ({ url: `/v2/prospects/due`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.PROSPECTS],
    }),
    getMyFutureMeetings: builder.query({
      query: () => ({ url: `/v2/prospects/meetings`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.PROSPECTS],
    }),
    updateMyProspect: builder.mutation({
      query: ({ prospectId, updates }) => ({ url: `/v2/prospects/${prospectId}`, method: 'PUT', body: updates }),
      invalidatesTags: [TAGS.PROSPECT, TAGS.PROSPECTS],
    }),
    addProspectContact: builder.mutation({
      query: ({ prospectId, contact }) => ({
        url: `/v2/prospects/${prospectId}/contacts`,
        method: 'POST',
        body: contact,
      }),
      invalidatesTags: [TAGS.PROSPECT, TAGS.PROSPECTS],
    }),
    updateProspectContact: builder.mutation({
      query: ({ prospectId, contactId, contact }) => ({
        url: `/v2/prospects/${prospectId}/contacts/${contactId}`,
        method: 'PUT',
        body: contact,
      }),
      invalidatesTags: [TAGS.PROSPECT, TAGS.PROSPECTS],
    }),
  }),
})

export const {
  useGetMyProspectsQuery,
  useGetCampaignProspectsQuery,
  useGetMyProspectQuery,
  useGetMyDueProspectsQuery,
  useGetMyFutureMeetingsQuery,
  useUpdateMyProspectMutation,
  useAddProspectContactMutation,
  useUpdateProspectContactMutation,
} = prospectsSlice
export default prospectsSlice
