import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const MuiWrapper = styled(Box, { name: 'DrawerActions', slot: 'Root' })(({ theme }) => ({
  width: '100%',
  minHeight: '64px',
  // theme.utils.pxToRem(64),
  display: 'inline-flex',
  justifyContent: 'center',
  overflow: 'hidden',
}))

export const CustomBox = styled(Box, { name: 'CustomBox', slot: 'Root' })(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // borderTop: `1px solid ${theme.palette.strokeDefault.main}`,
  gap: '12px',
  // theme.utils.pxToRem(12),
}))
