import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const MuiWrapper = styled(Box, { name: 'DrawerContainer', slot: 'Root' })(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export default MuiWrapper
