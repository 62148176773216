import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro'
import { LicenseInfo as MuiLicenseInfo } from '@mui/x-license-pro'

import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import AppConfig from '../../../../config'
import { createAlert } from '../../../../components/app-alert/slice'

import { useGetContactsQuery, useUpdateDecisionMakerMutation } from '../../../../services/contacts/slice'

MuiLicenseInfo.setLicenseKey(AppConfig.mui.licenseKey)

const ContactList = ({ prospect, campaign, setCurrentContact }) => {
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()
  const { data: contacts, isLoading } = useGetContactsQuery(
    { prospectId: prospect?.['Prospect ID'] },
    { skip: !prospect?.['Prospect ID'] }
  )
  const [updateDecisionMaker] = useUpdateDecisionMakerMutation()

  const strikeThroughClass = (params) => {
    return params.row.Inactive ? 'inactive-contact' : ''
  }

  const handleClipboardClick = (name, value) => {
    navigator.clipboard.writeText(value)
    dispatch(
      createAlert({
        message: `${name} copied to clipboard`,
        severity: 'success',
      })
    )
  }

  const columns = [
    { field: 'isDM', headerName: 'DM', width: 80, type: 'boolean', editable: true },
    { field: 'Name', headerName: 'Full Name', width: 150 },
    { field: 'Title', headerName: 'Title', width: 150 },
    { field: 'Department', headerName: 'Department', width: 150 },
    {
      field: 'Email',
      headerName: 'Email',
      width: 250,
      renderCell: (params) => {
        if (params.value)
          return (
            <Box component="span">
              <ContentCopyOutlinedIcon
                onClick={() => handleClipboardClick('Email', params.value)}
                sx={{ height: '0.75rem', cursor: 'pointer' }}
              />
              {params.value}
            </Box>
          )
        return params.value
      },
    },
    {
      field: 'Phone Number',
      headerName: 'Phone',
      width: 200,
      renderCell: (params) => {
        if (params.value)
          return (
            <Box component="span">
              <ContentCopyOutlinedIcon
                onClick={() => handleClipboardClick('Phone Number', params.value)}
                sx={{ height: '0.75rem', cursor: 'pointer' }}
              />
              {params.value}
            </Box>
          )
        return params.value
      },
    },
    {
      field: 'Mobile Number',
      headerName: 'Mobile Number',
      width: 200,
      renderCell: (params) => {
        if (params.value)
          return (
            <Box component="span">
              <ContentCopyOutlinedIcon
                onClick={() => handleClipboardClick('Mobile Number', params.value)}
                sx={{ height: '0.75rem', cursor: 'pointer' }}
              />
              {params.value}
            </Box>
          )
        return params.value
      },
    },
    { field: 'Notes', headerName: 'Notes', width: 250 },
  ]

  const isInactive = (value) => value === true || value === 'TRUE'

  const onRowDoubleClick = (params) => {
    setCurrentContact(params.row)
  }

  const onCellDoubleClick = (params, event) => {
    if (params.field === 'isDM') {
      event.stopPropagation()
      apiRef.current.startCellEditMode({ id: params.id, field: params.field })
    }
  }

  const processRowUpdate = (newRow, oldRow) => {
    if (newRow.isDM !== oldRow.isDM) {
      const contactId = newRow.isDM ? newRow['Contact ID'] : 'undefined'
      updateDecisionMaker({
        prospectId: prospect['Prospect ID'],
        campaignId: campaign.id,
        contactId,
      })
    }
    return newRow
  }

  const processRowUpdateError = useCallback((error) => {
    console.error('processRowUpdateError', error)
    return null
  }, [])

  const rows =
    contacts?.map((contact) => {
      const isDM = contact['Contact ID'] === prospect.campaign?.['Decision Maker Contact ID']
      return {
        id: contact['Contact ID'],
        isDM,
        ...contact,
        Inactive: isInactive(contact?.Inactive),
      }
    }) || []

  return (
    <DataGridPro
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      density="compact"
      disableRowSelectionOnClick
      hideFooter
      getRowClassName={strikeThroughClass}
      onRowDoubleClick={onRowDoubleClick}
      onCellDoubleClick={onCellDoubleClick}
      processRowUpdate={processRowUpdate}
      onProcessRowUpdateError={processRowUpdateError}
      slots={{
        loadingOverlay: LinearProgress,
      }}
      loading={isLoading}
      sx={{
        paddingBottom: 2,
        '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
        '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus': {
          outline: 'none !important',
        },
        '& .inactive-contact': {
          textDecoration: 'line-through',
        },
      }}
    />
  )
}

export default ContactList
