import { createAsyncThunk } from '@reduxjs/toolkit'

const impersonate = createAsyncThunk('impersonate/start', async ({ email, given_name, family_name }) => {
  return {
    given_name,
    family_name,
    email,
  }
})

export default impersonate
