import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import SearchIcon from '@mui/icons-material/Search'

import { debounce } from '@mui/material/utils'
import { useSearchQuery } from './slice'
import { useGetUsersQuery } from '../../services/users/slice'
import { useGetMyCampaignsQuery } from '../../services/campaigns/slice'

import SearchResult from './searchResult'
import ResultDialog from './resultDialog'

const NO_RESULTS_TEXT = 'No Results Found'

const SearchBar = () => {
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [searchTerm, setSearchTerm] = useState('')
  const [showDialogProspect, setShowDialogProspect] = useState()
  const [searchResults, setSearchResults] = useState([])
  const navigate = useNavigate()

  const { data: rawSearchResults, isFetching } = useSearchQuery({ searchTerm }, { skip: searchTerm.length < 3 })
  const { data: users, isFetching: isUsersFetching } = useGetUsersQuery()
  const { data: campaigns, isFetching: isCampaignsFetching } = useGetMyCampaignsQuery()

  useEffect(() => {
    if (rawSearchResults && searchTerm.length >= 3) {
      setSearchResults(rawSearchResults)
    } else {
      setSearchResults([])
    }
  }, [rawSearchResults, searchTerm.length])

  // Update options whenever searchResults changes
  useEffect(() => {
    if (!isFetching && !isCampaignsFetching && !isUsersFetching && searchResults) {
      if (searchResults.length === 0) {
        if (searchTerm.length >= 3) {
          setOptions([{ Name: NO_RESULTS_TEXT, 'Prospect ID': 'undefined', campaign: { id: 'undefined' } }])
        } else {
          setOptions([])
        }
      } else {
        const results = searchResults.map((result) => {
          const assignedTo = result.campaign?.['Assigned To User ID']?.trim()
            ? users.find((user) => user.email === result.campaign['Assigned To User ID'])
            : undefined
          return {
            ...result,
            campaign: {
              ...result.campaign,
              'Assigned To User': assignedTo ? `${assignedTo?.givenName} ${assignedTo?.familyName}` : 'Unassigned',
            },
          }
        })
        setOptions(results)
      }
    }
  }, [searchResults, isFetching, users, isUsersFetching, isCampaignsFetching, searchTerm.length])

  // Throttle function to set search term
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSetSearchTerm = useCallback(debounce(setSearchTerm, 300), [])

  // delayed navigate to the selected prospect
  const navigateToProspect = (campaignId, prospectId) => {
    setTimeout(() => {
      navigate(`/campaigns/${campaignId}/prospects/${prospectId}`)
    }, 100)
  }

  const resetValues = () => {
    setSearchResults([])
    setInputValue('')
    setSearchTerm('')
  }

  const handleSelect = (event, value) => {
    // If this prospect is a campaign that is not assigned to the user, show a dialog with the prospect id.
    // Otherwise, navigate to the prospect.
    if (!campaigns.find((c) => c.id === value?.campaign?.id)) setShowDialogProspect(value?.['Prospect ID'])
    else if (value?.campaign?.id && value?.['Prospect ID'] && value?.Name !== NO_RESULTS_TEXT) {
      navigateToProspect(value.campaign.id, value?.['Prospect ID'])
    }
    resetValues()
  }

  const handleInputChange = (_, value, reason) => {
    if (reason === 'input') {
      setInputValue(value)
    }
    throttledSetSearchTerm(value)
  }

  const handleBlur = () => {
    return resetValues()
  }

  return (
    <>
      <ResultDialog open={showDialogProspect} setOpen={setShowDialogProspect} />
      <Autocomplete
        freeSolo
        blurOnSelect
        clearOnEscape
        sx={{ width: 500, marginRight: 25 }}
        onInputChange={handleInputChange}
        getOptionLabel={(a) => a?.Name ?? a ?? ''}
        onChange={handleSelect}
        options={options}
        loading={isFetching}
        filterOptions={(x) => x}
        isOptionEqualToValue={() => false}
        value={inputValue}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Search for Prospects"
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                // Default border color (transparent) when not focused/hovered
                '& fieldset': {
                  borderColor: 'transparent',
                },
                // Border color changes on hover
                '&:hover fieldset': {
                  borderColor: 'lightgrey', // Change this color as needed
                },
                // Border color when the input is focused
                '&.Mui-focused fieldset': {
                  borderColor: 'primary.main', // Use your theme's primary color
                },
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon sx={{ color: 'lightgrey' }} />,
              endAdornment: (
                <>{isFetching ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</>
              ),
            }}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option?.['Prospect ID'] + option.campaign.id}>
            <SearchResult option={option} />
          </li>
        )}
      />
    </>
  )
}

export default SearchBar
