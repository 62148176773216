import apiSlice from '../api/slice'
import { TAGS } from '../../constants/tags'
import AppConfig from '../../config'

const versionSlice = apiSlice.injectEndpoints({
  name: 'version',
  endpoints: (builder) => ({
    getRequiredVersion: builder.query({
      query: () => ({ url: `/v2/version/${AppConfig.app.platform}` }),
      transformResponse: (response) => response.data,
      providesTags: TAGS.VERSION,
      keepUnusedDataFor: 5 * 60,
    }),
  }),
})

export const { useGetRequiredVersionQuery } = versionSlice

export default versionSlice
