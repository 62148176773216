import { Box, Paper, Typography, Tooltip } from '@mui/material'
import { formatShortDate } from '../../../utils/dates'

const DailyChart = ({ segments, date }) => {
  if (!segments) return null

  // Calculate the start and end of the timeline
  const dateString = `${date}T00:00:00`
  const timelineStart = new Date(dateString).setHours(8, 0, 0, 0)
  const timelineEnd = new Date(dateString).setHours(18, 0, 0, 0)
  const timelineDuration = timelineEnd - timelineStart
  const totalActiveDuration = segments.reduce((acc, segment) => (segment.isBreak ? acc : acc + segment.duration), 0)

  // Format totalActiveDuration as h:mm
  const activeHours = Math.floor(totalActiveDuration / 60)
  const activeMinutes = Math.floor(totalActiveDuration % 60)
  const totalActiveDurationFormatted = `${activeHours}:${activeMinutes.toString().padStart(2, '0')}`

  const firstSegmentStart = segments?.length !== 0 ? new Date(segments[0].startTime).getTime() : 0
  const lastSegmentEnd = segments?.length !== 0 ? new Date(segments?.[segments.length - 1].endTime).getTime() : 0

  // Calculate the total time span of all segments
  const totalTimeSpan = lastSegmentEnd - firstSegmentStart

  // Calculate the break duration
  const breakDuration = Math.max(Math.round((totalTimeSpan - totalActiveDuration) / 1000 / 60) - totalActiveDuration, 0)

  // Format breakDuration as h:mm
  const breakHours = Math.floor(breakDuration / 60)
  const breakMinutes = Math.floor(breakDuration % 60)
  const breakDurationFormatted = `${breakHours}:${breakMinutes.toString().padStart(2, '0')}`

  // Function to calculate the left offset and width of each segment
  const calculateSegmentStyle = (startTime, endTime) => {
    const start = new Date(startTime).getTime()
    const end = new Date(endTime).getTime()
    const left = ((start - timelineStart) / timelineDuration) * 100
    const width = ((end - start) / timelineDuration) * 100
    return { left: `${left}%`, width: `${width}%` }
  }

  if (!segments) return null

  return (
    <Box display={'flex'}>
      <Box
        width="11%"
        maxWidth="11%"
        sx={{ position: 'relative', height: '24px', display: 'flex', borderBottom: '1px solid lightgrey' }}
      >
        <Typography width="100%" align={'center'} noWrap>
          {formatShortDate(new Date(dateString))}
        </Typography>
      </Box>
      <Box
        sx={{
          bgcolor: '#FFF',
          position: 'relative',
          height: '24px',
          width: '75%',
          borderBottom: '1px solid lightgrey',
        }}
      >
        {segments.map((segment, index) =>
          !segment.isBreak ? (
            <Tooltip
              key={index}
              title={
                <Box>
                  {`From: ${new Date(segment.startTime).toLocaleTimeString()}`}
                  <br />
                  {`To: ${new Date(segment.endTime).toLocaleTimeString()}`}
                  <br />
                  {`Type: ${segment.type || 'pause'}`}
                </Box>
              }
            >
              <Paper
                key={index}
                elevation={2}
                square
                sx={{
                  position: 'absolute',
                  top: '0',
                  bottom: '0',
                  backgroundColor:
                    segment.type === 'call' ? '#8Bcf24' : segment.type === 'event' ? '#ffa726' : 'lightgrey',
                  ...calculateSegmentStyle(segment.startTime, segment.endTime),
                }}
              />
            </Tooltip>
          ) : null
        )}
      </Box>
      <Box
        width="7%"
        maxWidth="7%"
        sx={{ position: 'relative', height: '24px', display: 'flex', borderBottom: '1px solid lightgrey' }}
      >
        <Typography width="100%" align={'center'}>
          {totalActiveDurationFormatted}
        </Typography>
      </Box>
      <Box
        width="7%"
        maxWidth="7%"
        sx={{ position: 'relative', height: '24px', display: 'flex', borderBottom: '1px solid lightgrey' }}
      >
        <Typography width="100%" align={'center'}>
          {breakDurationFormatted}
        </Typography>
      </Box>
    </Box>
  )
}

export default DailyChart
