import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { setTimesheetStartDate, setTimesheetEndDate } from '../../../services/app/slice'

const width = '9rem'

const TimesheetDateSelector = ({ name, type }) => {
  const dispatch = useDispatch()
  const stateTimesheetStartDate = useSelector((state) => state.app.timesheet.startDate)
  const stateTimesheetEndDate = useSelector((state) => state.app.timesheet.endDate)
  const timesheetStartDate = useMemo(
    () => (stateTimesheetStartDate ? new Date(stateTimesheetStartDate) : null),
    [stateTimesheetStartDate]
  )
  const timesheetEndDate = useMemo(
    () => (stateTimesheetEndDate ? new Date(stateTimesheetEndDate) : null),
    [stateTimesheetEndDate]
  )
  const selectedDate = type === 'start' ? timesheetStartDate : timesheetEndDate

  const onDateChange = (date) => {
    if (type === 'start' && timesheetEndDate && date && date > timesheetEndDate) {
      dispatch(setTimesheetEndDate(date?.toISOString()))
    }
    if (type === 'end' && timesheetStartDate && date && date < timesheetStartDate) {
      dispatch(setTimesheetStartDate(date?.toISOString()))
    }
    // dispatch(setEventsListPage(0))
    dispatch(type === 'start' ? setTimesheetStartDate(date?.toISOString()) : setTimesheetEndDate(date?.toISOString()))
  }

  // const onDateChange = (date) => {

  //   dispatch(type === 'start' ? setTimesheetStartDate(date?.toISOString()) : setTimesheetEndDate(date?.toISOString()))
  // }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        name={name}
        label={name}
        value={selectedDate || null}
        onChange={(date) => onDateChange(date)}
        slotProps={{
          textField: {
            variant: 'outlined',
            size: 'small',
            sx: {
              width: width,
            },
            InputLabelProps: {
              shrink: true,
              sx: {
                '&.MuiInputLabel-shrink': {
                  transform: 'translate(0.9rem, -0.3rem) scale(0.75)',
                },
              },
            },
          },
        }}
        format="yyyy-MM-dd"
      />
    </LocalizationProvider>
  )
}

export default TimesheetDateSelector
