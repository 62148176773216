import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Stack, Box, Typography } from '@mui/material'
import { useGetMyStatisticsQuery } from '../../../services/statistics/slice'
import { ROUTES } from '../../../constants/routes'

const formatAsTime = (intMinutes) => {
  const hours = Math.floor(intMinutes / 60)
  const minutes = Math.floor(intMinutes % 60)
  const minutesWithZero = minutes < 10 ? `0${minutes}` : minutes
  return `${hours}:${minutesWithZero}`
}

const Statistics = () => {
  const navigate = useNavigate()
  const { data: statistics } = useGetMyStatisticsQuery(undefined, {
    pollingInterval: 60000,
    keepUnusedDataFor: 1000,
  })

  if (!statistics) {
    return null
  }

  const color =
    statistics?.total?.perHour?.current < 14
      ? '#d32f2f'
      : statistics?.total?.perHour?.current < 24
      ? '#ffa726'
      : '#8Bcf24'

  return (
    <Stack direction={'row'} spacing={0} padding={0} margin={0}>
      <Tooltip title="Calls in the last hour" placement="top">
        <Box onClick={() => navigate(ROUTES.TIMESHEET)} sx={{ cursor: 'pointer' }}>
          <Gauge
            valueMin={0}
            valueMax={40}
            value={Math.min(statistics?.total?.perHour?.current, 40)}
            startAngle={-90}
            endAngle={90}
            sx={{
              transform: 'translate(0px,-5px)',
              height: '50px',
              width: '70px',
              [`& .${gaugeClasses.valueArc}`]: {
                fill: color,
              },
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 12,
                transform: 'translate(0px,-5px)',
              },
            }}
            text={() => `${Math.round(statistics?.total?.perHour?.current * 10) / 10}`}
          />
        </Box>
      </Tooltip>
      <Tooltip title="Active Time Today" placement="top">
        <Box marginLeft={1} onClick={() => navigate(ROUTES.TIMESHEET)} sx={{ cursor: 'pointer' }}>
          <Typography
            margin={0}
            padding={0}
            sx={{ fontWeight: 400, fontSize: '0.5rem', transform: 'translate(0px,4px)' }}
          >
            Active Time
          </Typography>
          <Typography
            margin={0}
            padding={0}
            sx={{ fontWeight: 400, fontSize: '1.25rem', transform: 'translate(0px,-2px)' }}
          >
            {formatAsTime(statistics?.total?.nonInactiveTime)}
          </Typography>
        </Box>
      </Tooltip>
      <Tooltip title="Break Time Today" placement="top">
        <Box marginLeft={2} onClick={() => navigate(ROUTES.TIMESHEET)} sx={{ cursor: 'pointer' }}>
          <Typography
            margin={0}
            padding={0}
            sx={{ fontWeight: 400, fontSize: '0.5rem', transform: 'translate(0px,4px)' }}
          >
            Break Time
          </Typography>
          <Typography
            margin={0}
            padding={0}
            sx={{ fontWeight: 400, fontSize: '1.25rem', transform: 'translate(0px,-2px)' }}
          >
            {formatAsTime(statistics?.total?.breakTime)}
          </Typography>
        </Box>
      </Tooltip>
    </Stack>
  )
}

export default Statistics
