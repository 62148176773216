import { createAction, isRejectedWithValue } from '@reduxjs/toolkit'

const apiErrorHandler = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.payload) {
      const sendRestApiErrorAction = createAction(`api/error/${action.payload.target}`)
      api.dispatch(sendRestApiErrorAction(action.payload))
    }
  }

  return next(action)
}

export default apiErrorHandler
