import apiSlice from '../api/slice'

const statisticsSlice = apiSlice.injectEndpoints({
  name: 'statistics',
  endpoints: (builder) => ({
    getMyStatistics: builder.query({
      query: () => ({ url: `/v3/statistics/my`, method: 'GET' }),
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 15,
    }),
    getMySegments: builder.query({
      query: (params) => ({ url: `/v3/statistics/segments/my`, method: 'GET', params }),
      transformResponse: (response) => response.data,
      keepUnusedDataFor: 15,
    }),
  }),
})

export const { useGetMyStatisticsQuery, useGetMySegmentsQuery } = statisticsSlice

export default statisticsSlice
