import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import refresh from '../auth/cognito/refresh'
import AppConfig from '../../config'
import { TAGS_ARRAY } from '../../constants/tags'
import { createAlert } from '../../components/app-alert/slice'

const baseQuery = fetchBaseQuery({
  baseUrl: AppConfig.api.baseUrl,
  tagTypes: TAGS_ARRAY,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.persisted?.auth?.tokens?.AccessToken
    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }
    const impersonation = getState()?.persisted?.auth?.impersonation?.email
    if (impersonation) {
      headers.set('Impersonation', impersonation)
    }
    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error?.status === 403 || result?.error?.status === 401) {
    await api.dispatch(refresh())
    result = await baseQuery(args, api, extraOptions)
  }

  // Handle 503 status code
  if (result?.error?.status === 503) {
    api.dispatch(
      createAlert({
        message: 'The server took too long to respond. Retrying the request...',
      })
    )
    result = await baseQuery(args, api, extraOptions)
  }

  return result
}

const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
})

export default apiSlice
