import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggle } from './slice'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from '@mui/material/Typography'

import { CustomDrawer, CustomDrawerHeader } from './styled'
import EventForm from '../../features/prospect/components/events/eventForm'

const EventDrawer = () => {
  const dispatch = useDispatch()
  const { isOpen, currentProspect, currentCampaign } = useSelector((state) => state.eventDrawer)
  const [submitEvent, setSubmitEvent] = useState()
  const [cancelEvent, setCancelEvent] = useState()
  const [isSaving, setIsSaving] = useState(false)

  const handleDrawerToggle = () => {
    dispatch(toggle())
  }

  const handleSave = (e) => {
    setSubmitEvent(e)
  }

  const setShowDrawer = () => {
    handleDrawerToggle()
  }

  const handleCancel = (e) => {
    setCancelEvent(e)
    handleDrawerToggle()
  }

  return (
    <CustomDrawer variant="permanent" anchor="bottom" open={isOpen}>
      <CustomDrawerHeader>
        <Box>
          <Typography fontSize={18}>LOG AN EVENT</Typography>
        </Box>
        <Box>
          <Button variant="outlined" color="error" onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton variant="contained" onClick={handleSave} sx={{ ml: 2 }} loading={isSaving}>
            Save
          </LoadingButton>
        </Box>
      </CustomDrawerHeader>
      <EventForm
        campaign={currentCampaign}
        prospect={currentProspect}
        submitEvent={submitEvent}
        setSubmitEvent={setSubmitEvent}
        cancelEvent={cancelEvent}
        setShowDrawer={setShowDrawer}
        setIsSaving={setIsSaving}
      />
    </CustomDrawer>
  )
}

export default EventDrawer
