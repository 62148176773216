import { useContext, useEffect } from 'react'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'

import { useFormik, FormikProvider } from 'formik'
import ProspectContext from '../prospect/context'

import Field from './field'
import { useUpdateMyProspectMutation, useGetMyProspectQuery } from '../../../prospects/slice'

const Section = ({ campaign, prospect, section }) => {
  const { data, setData } = useContext(ProspectContext)
  const [updateMyProspectMutation, { isLoading: isSaving }] = useUpdateMyProspectMutation()
  const { isLoading } = useGetMyProspectQuery(
    { prospectId: prospect?.['Prospect ID'], campaignId: campaign?.id },
    { skip: !prospect?.['Prospect ID'] || !campaign?.id }
  )
  const isAllReadonly = section?.fields?.every((field) => Boolean(field?.['ReadOnly'])) ?? true
  const orderedFields = section?.fields?.slice()?.sort((a, b) => a.Priority - b.Priority) || []

  const getThisEntity = () => {
    if (section) {
      if (section.entity === 'campaigns') return campaign
      if (section.Entity === 'Prospects') return prospect
    }
  }

  const thisEntity = getThisEntity()

  const getInitialValues = () => {
    const initialValues = {
      id: thisEntity?.['Prospect ID'],
    }

    orderedFields.forEach((field) => {
      initialValues[field?.['Field ID']] = thisEntity[field?.['Field ID']] ?? ''
    })

    return initialValues
  }

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      // TODO: Since this could be a campaign, or prospect, we need to figure out which one it is and update it
      if (section.entity === 'campaigns') return
      if (section.Entity === 'Prospects')
        return updateMyProspectMutation({ prospectId: prospect['Prospect ID'], updates: values })
    },
    enableReinitialize: true,
  })

  const handleSaveClick = () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    if (data[section?.['Section Name']] !== formik.dirty) {
      setData({ ...data, [section?.['Section Name']]: formik.dirty })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.dirty])

  return (
    <Box px={2} py={1}>
      <Paper>
        <FormikProvider value={formik}>
          <Box padding={2} margins={3} borderBottom={1} borderColor={'lightgray'}>
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
              <Box display="flex" flexDirection="row" alignItems="center" variant="span">
                <Typography fontSize={18} mr={1}>
                  {section?.['Section Name']?.toUpperCase()}
                </Typography>
                {(isSaving || isLoading) && <CircularProgress size={18} />}
              </Box>
              {!isAllReadonly && (
                <LoadingButton
                  variant="contained"
                  onClick={handleSaveClick}
                  disabled={!formik.dirty || section.entity === 'campaigns'}
                  loading={isSaving || isLoading}
                >
                  Save
                </LoadingButton>
              )}
            </Box>
            {orderedFields?.map((field) => (
              <Box key={field['Field ID']} display="flex" flexDirection="row" alignItems="center">
                <Field key={field['Field ID']} field={field} />
              </Box>
            ))}
          </Box>
        </FormikProvider>
      </Paper>
    </Box>
  )
}

export default Section
