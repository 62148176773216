import { parse, parseISO, isValid } from 'date-fns'

const convertToDate = (dateString) => {
  if (!dateString || isValid(dateString)) {
    return dateString
  }

  const formats = [
    'yyyy-MM-dd H:mm:ss', // Format for "2024-05-08 14:30:00"
    'yyyy-MM-dd h:mm:ss a', // Format for "2024-05-08 2:30:00 PM"
    'yyyy-MM-dd H:mm', // Format for "2024-05-08 14:30"
    'yyyy-MM-dd h:mm a', // Format for "2024-05-08 2:30 PM"
    'yyyy-MM-dd', // Format for "2024-05-08"
    'MMM yyyy', // Format for "May 2024"
    'MMM d yyyy', // Format for "May 8 2024"
    'MMM d, yyyy', // Format for "May 8 2024"
    'MMMM d yyyy', // Format for "May 8 2024"
    'MMMM d, yyyy', // Format for "May 8 2024"
    'M/d/yyyy H:mm', // Format for "5/8/2024 14:30"
    'M/d/yyyy H:mm:ss', // Format for "5/8/2024 14:30:00"
    'M/d/yyyy h:mm a', // Format for "5/8/2024 2:30 PM"
    'M/d/yyyy h:mm:ss a', // Format for "5/8/2024 2:30:00 PM"
  ]

  const isoDate = parseISO(dateString)
  if (isValid(isoDate)) {
    return isoDate
  }

  for (const format of formats) {
    const parsedDate = parse(dateString, format, new Date())
    if (isValid(parsedDate)) {
      return parsedDate
    }
  }

  console.log('Invalid date:', dateString)
  return null
}

const formatDate = (date) => {
  if (isValid(date)) {
    let result = date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    return result?.replace(' at ', ', ')
  } else {
    console.log('Invalid date:', date)
    return date
  }
}

const formatShortDate = (date) => {
  if (isValid(date)) {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  } else {
    console.log('Invalid date:', date)
    return date
  }
}

export { convertToDate, formatDate, formatShortDate }
