import DailyChart from './dailyChart'
import DailyChartLabels from './dailyChartLabels'
import DailyChartHeader from './dailyChartHeader'

const ActivityChart = ({ segments, isLoading }) => {
  if (isLoading) return <div>Loading...</div>
  if (!segments) return null
  return (
    <>
      <DailyChartHeader />
      {segments.map((segment, index) => (
        <DailyChart key={index} segments={segment.segments} date={segment.date} />
      ))}
      <DailyChartLabels />
    </>
  )
}

export default ActivityChart
