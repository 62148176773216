import { Field } from 'formik'
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const DatePicker = ({ name, width, ...props }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const isError = !!((meta.touched || form.submitCount > 0) && meta.error)
        return (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MuiDatePicker
              {...props}
              {...field}
              error={isError}
              helperText={isError ? meta.error : ''}
              value={field.value || null}
              onChange={(date) => form.setFieldValue(name, date)}
              slotProps={{ textField: { variant: 'outlined', size: 'small', sx: { width: width || '100%' } } }}
              format="yyyy-MM-dd"
            />
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

export default DatePicker
