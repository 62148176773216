import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'

import { createAlert } from '../../../../components/app-alert/slice'

import ContactDrawer from './contactDrawer'
import ContactList from './contactList'

const Contacts = ({ campaign, prospect }) => {
  const dispatch = useDispatch()
  const [showDrawer, setShowDrawer] = useState(false)
  const [currentContact, setCurrentContact] = useState()

  useEffect(() => {
    if (currentContact) {
      setShowDrawer(true)
    }
  }, [currentContact])

  useEffect(() => {
    if (!showDrawer) {
      setCurrentContact()
    }
  }, [showDrawer])

  const onAddClick = () => {
    setCurrentContact()
    setShowDrawer(true)
  }

  const handleClipboardClick = (name, value) => {
    navigator.clipboard.writeText(value)
    dispatch(
      createAlert({
        message: `${name} copied to clipboard`,
        severity: 'success',
      })
    )
  }

  return (
    <>
      <Box px={2} py={0}>
        <Paper>
          <Box display="flex" alignItems="center" paddingY={2} margin={2}>
            <Box display="flex" alignItems="center">
              <Typography pt={'2px'} fontSize={18}>
                CONTACTS
              </Typography>
              <IconButton size="small" color="primary" onClick={onAddClick}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>
            <Box flexGrow={1} />
            <Box display="flex" alignItems="center" pr={2}>
              {prospect?.['Main Phone Number'] && (
                <>
                  <Box component="span">
                    <ContentCopyOutlinedIcon
                      onClick={() => handleClipboardClick('Main Phone Number', prospect?.['Main Phone Number'])}
                      sx={{ height: '0.75rem', cursor: 'pointer' }}
                    />
                  </Box>
                  <Typography>
                    Primary Phone: <b>{prospect?.['Main Phone Number']}</b>
                  </Typography>
                </>
              )}
              {prospect?.['Secondary Phone Number'] && (
                <>
                  <Box component="span" pl={2}>
                    <ContentCopyOutlinedIcon
                      onClick={() =>
                        handleClipboardClick('Secondary Phone Number', prospect?.['Secondary Phone Number'])
                      }
                      sx={{ height: '0.75rem', cursor: 'pointer' }}
                    />
                  </Box>
                  <Typography>
                    Secondary Phone: <b>{prospect?.['Secondary Phone Number']}</b>
                  </Typography>
                </>
              )}
              {prospect?.['Phone Notes'] && (
                <Typography pl={2}>
                  Phone Notes: <b>{prospect?.['Phone Notes']}</b>
                </Typography>
              )}
            </Box>
          </Box>
          <ContactList campaign={campaign} prospect={prospect} setCurrentContact={setCurrentContact} />
        </Paper>
      </Box>
      <ContactDrawer
        prospect={prospect}
        campaign={campaign}
        contact={currentContact}
        showDrawer={showDrawer}
        setShowDrawer={setShowDrawer}
      />
    </>
  )
}

export default Contacts
