import { createSlice } from '@reduxjs/toolkit'
import { login } from '../../services/auth/slice'

const initialState = {
  showAlert: false,
  severity: undefined,
  message: undefined,
}

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    createAlert: (state, action) => {
      state.showAlert = true
      state.severity = action.payload.severity || 'warning'
      state.message = action.payload.message
    },
    clearAlert: (state) => {
      state.showAlert = false
      state.severity = undefined
      state.message = undefined
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase('api/executeMutation/rejected', (state, action) => {
        if (action.payload) {
          console.error('api/executeMutation/rejected', action.payload)
          state.showAlert = true
          state.severity = 'error'
          state.message = action.payload?.data?.message || action.payload?.data?.error || 'Something went wrong'
        }
      })
      .addCase('api/executeQuery/rejected', (state, action) => {
        if (action.payload) {
          console.error('api/executeQuery/rejected', action.payload)
          state.showAlert = true
          state.severity = 'error'
          state.message = action.payload?.data?.message || action.payload?.data?.error || 'Something went wrong'
        }
      })
      .addCase(login.rejected, (state, action) => {
        state.showAlert = true
        state.severity = 'error'
        state.message = action.error?.message || 'An error occurred during login'
      })
  },
})

export const { createAlert, clearAlert } = alertSlice.actions
export default alertSlice
