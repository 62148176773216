import apiSlice from '../api/slice'

const interfaceSlice = apiSlice.injectEndpoints({
  name: 'interface',
  endpoints: (builder) => ({
    performRefresh: builder.mutation({
      query: () => ({
        url: `/v2/interface/cache`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const { usePerformRefreshMutation } = interfaceSlice

export default interfaceSlice
