import { createSlice } from '@reduxjs/toolkit'

const today = new Date()
today.setHours(0, 0, 0, 0) // Set to start of the day

const initialState = {
  timesheet: {
    startDate: today.toISOString(),
    endDate: today.toISOString(),
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTimesheetStartDate: (state, action) => {
      state.timesheet.startDate = action.payload
    },
    setTimesheetEndDate: (state, action) => {
      state.timesheet.endDate = action.payload
    },
  },
})

export const { setTimesheetStartDate, setTimesheetEndDate } = appSlice.actions

export default appSlice
