import { useState } from 'react'
import {
  RightDrawer,
  DrawerActions,
  DrawerContainer,
  DrawerContent,
  DrawerHeader,
} from '../../../../components/right-drawer'
import ContactForm from './contactForm'

const ContactDrawer = ({ prospect, contact, campaign, showDrawer, setShowDrawer }) => {
  const [onSubmitEvent, setSubmitEvent] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  return (
    <RightDrawer showDrawer={showDrawer} setShowDrawer={setShowDrawer}>
      <DrawerHeader title={`${contact ? 'Edit' : 'Add'} Contact`} setShowDrawer={setShowDrawer} />
      <DrawerContainer>
        <DrawerContent>
          <ContactForm
            prospect={prospect}
            campaign={campaign}
            contact={contact}
            onSubmitEvent={onSubmitEvent}
            setSubmitEvent={setSubmitEvent}
            setShowDrawer={setShowDrawer}
            setIsSaving={setIsSaving}
          />
        </DrawerContent>
        <DrawerActions
          buttons={[
            {
              action: () => {
                setShowDrawer(false)
              },
              color: 'error',
              label: 'Cancel',
              variant: 'outlined',
            },
            {
              action: setSubmitEvent,
              color: 'primary',
              label: 'Save',
              variant: 'contained',
              loading: isSaving,
            },
          ]}
        />
      </DrawerContainer>
    </RightDrawer>
  )
}

export default ContactDrawer
