import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'

import { CustomBox, MuiWrapper } from './styled'

function DrawerHeader({ title, setShowDrawer, preventClosing, ...muiBoxProps }) {
  return (
    <MuiWrapper component="header" {...muiBoxProps}>
      <CustomBox>
        <Typography marginLeft={2} fontSize={18}>
          {title.toUpperCase()}
        </Typography>
        {!preventClosing && (
          <IconButton
            onClick={() => {
              setShowDrawer(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </CustomBox>
    </MuiWrapper>
  )
}

export default DrawerHeader
