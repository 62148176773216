import { useEffect } from 'react'
import MuiBox from '@mui/material/Box'
import { useFormik, Form, FormikProvider } from 'formik'
import * as Yup from 'yup'
import FormikControl from '../../../../components/formik-control'
import { useAddContactMutation, useUpdateContactMutation } from '../../../../services/contacts/slice'

const ContactForm = ({ prospect, contact, campaign, onSubmitEvent, setSubmitEvent, setShowDrawer, setIsSaving }) => {
  const [addContact] = useAddContactMutation()
  const [updateContact] = useUpdateContactMutation()

  const isInactive = (value) => value === true || value === 'TRUE'

  const initialValues = {
    id: contact?.['Contact ID'],
    Name: contact?.Name || '',
    Title: contact?.Title || '',
    Department: contact?.Department || '',
    'Phone Number': contact?.['Phone Number'] || '',
    'Mobile Number': contact?.['Mobile Number'] || '',
    Email: contact?.Email || '',
    Notes: contact?.Notes || '',
    Inactive: isInactive(contact?.Inactive),
  }

  const validationSchema = Yup.object({
    Name: Yup.string().required('Required'),
    Email: Yup.string().email(),
    Inactive: Yup.boolean(),
  })

  const onSubmit = async (values) => {
    setIsSaving(true)
    if (values.id)
      await updateContact({
        campaignId: campaign.id,
        contactId: values.id,
        contact: values,
      })
    else
      await addContact({
        prospectId: prospect['Prospect ID'],
        campaignId: campaign.id,
        contact: values,
      })
    setSubmitEvent()
    setIsSaving(false)
    setShowDrawer(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (onSubmitEvent) {
      formik.handleSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onSubmitEvent])

  return (
    <FormikProvider value={formik}>
      <Form>
        <MuiBox>
          <FormikControl control="input" label="Full Name" name="Name" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Title" name="Title" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Department" name="Department" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Phone Number" name="Phone Number" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Mobile Number" name="Mobile Number" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Email" name="Email" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="input" label="Notes" name="Notes" fullWidth margin="normal" />
        </MuiBox>
        <MuiBox>
          <FormikControl control="checkbox" label="Inactive" name="Inactive" margin="normal" />
        </MuiBox>
      </Form>
    </FormikProvider>
  )
}

export default ContactForm
