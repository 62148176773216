import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import Container from '@mui/material/Container'

import { login } from '../../services/auth/slice'
import { useFormik, FormikProvider, Form } from 'formik'
import * as Yup from 'yup'

import FormikControl from '../../components/formik-control'

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, isLoading } = useSelector((state) => state.persisted.auth)

  useEffect(() => {
    if (user) {
      navigate('/')
    }
  }, [user, navigate])

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
  })

  const onSubmit = (values) => {
    dispatch(login({ username: values.email, password: values.password }))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <Container maxWidth="xs">
      <FormikProvider value={formik}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 8,
            alignItems: 'center',
          }}
        >
          <Form>
            <FormikControl
              control="input"
              type="email"
              name="email"
              label="Email Address"
              autoComplete="email"
              fullWidth
              autoFocus
              margin="normal"
            />
            <FormikControl
              control="input"
              type="password"
              name="password"
              label="Password"
              autoComplete="current-password"
              fullWidth
              margin="normal"
            />
            <LoadingButton
              type="submit"
              variant="contained"
              margin="normal"
              disabled={!formik.isValid || !formik.dirty}
              loading={isLoading}
              sx={{
                width: '170px',
                display: 'flex',
                margin: 'auto',
                mt: 2,
              }}
            >
              Sign In
            </LoadingButton>
          </Form>
        </Box>
      </FormikProvider>
    </Container>
  )
}

export default Login
