import { combineReducers, configureStore } from '@reduxjs/toolkit'
import apiSlice from '../services/api/slice'
import apiErrorHander from '../services/api/error-handler'
import authSlice from '../services/auth/slice'
import appSlice from '../services/app/slice'
import eventDrawerSlice from '../components/event-drawer/slice'
import alertSlice from '../components/app-alert/slice'
import searchBarSlice from '../components/search-bar/slice'
import interfaceSlice from '../services/interface/slice'

import campaignsSlice from '../services/campaigns/slice'
import contactsSlice from '../services/contacts/slice'
import prospectsSlice from '../features/prospects/slice'
import dispositionsSlice from '../services/dispositions/slice'
import eventsSlice from '../services/events/slice'
import fieldsSlice from '../services/fields/slice'
import usersSlice from '../services/users/slice'
import versionSlice from '../services/version/slice'
import statisticsSlice from '../services/statistics/slice'

import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
}

const rootReducer = combineReducers({
  auth: authSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: {
    api: apiSlice.reducer,
    app: appSlice.reducer,
    alert: alertSlice.reducer,
    eventDrawer: eventDrawerSlice.reducer,
    searchBar: searchBarSlice.reducer,
    interface: interfaceSlice.reducer,
    campaigns: campaignsSlice.reducer,
    contacts: contactsSlice.reducer,
    prospects: prospectsSlice.reducer,
    dispositions: dispositionsSlice.reducer,
    events: eventsSlice.reducer,
    fields: fieldsSlice.reducer,
    users: usersSlice.reducer,
    version: versionSlice.reducer,
    statistics: statisticsSlice.reducer,
    persisted: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware, apiErrorHander),
})

export default store
