import MuiWrapper from './styled'

function DrawerContent({ children, ...muiBoxProps }) {
  return (
    <MuiWrapper component="section" {...muiBoxProps}>
      {children}
    </MuiWrapper>
  )
}

export default DrawerContent
