export const TAGS = {
  CAMPAIGNS: 'campaigns',
  CAMPAIGN: 'campaign',
  CONTACTS: 'contacts',
  CLIENTS: 'clients',
  DISPOSITIONS: 'dispositions',
  EVENTS: 'events',
  EVENTTYPES: 'eventTypes',
  MEETINGS: 'meetings',
  PROSPECTS: 'prospects',
  PROSPECT: 'prospect',
  FIELDS_CAMPAIGN: 'fields_campaign',
  FIELDS_ENTITY: 'fields_entity',
  USERS: 'users',
  VERSION: 'version',
}

export const TAGS_ARRAY = Object.values(TAGS)
