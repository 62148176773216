import { convertToDate, formatDate } from '../../../utils/dates'

import { Box, Typography } from '@mui/material'

const MeetingItemBookedStatus = ({ prospect }) => {
  return (
    <Box>
      <Typography
        component="span"
        sx={{
          marginLeft: 2,
          color: prospect.event?.isNew ? (prospect.event.isStillBooked ? 'primary.main' : 'grey') : 'error.main',
        }}
      >
        {prospect.event?.isNew ? 'NEW: ' : 'REVISED: '}
      </Typography>
      <Typography component="span" color="grey">
        {prospect.event?.isNew
          ? `Booked on ${formatDate(convertToDate(prospect.event?.lastMeetingEvent?.createdDate))}`
          : `Initially booked on ${formatDate(convertToDate(prospect.event?.firstMeetingEvent?.createdDate))}`}
      </Typography>
      {!prospect.event?.isStillBooked && (
        <>
          <Typography component="span" color="grey">
            {' - '}
          </Typography>
          <Typography component="span" color="error.main">
            {'MODIFIED: '}
          </Typography>
          <Typography component="span" color="grey">
            {prospect.campaign?.Disposition}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default MeetingItemBookedStatus
