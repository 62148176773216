import PageTitle from '../../components/page-title'
import Campaigns from './components/campaigns'
import Overdue from './components/dueProspects'
import MyMeetings from './components/myMeetings'
import Statistics from './components/statistics'

import { Divider } from '@mui/material'

const Dashboard = () => {
  return (
    <>
      <PageTitle title="Dashboard" action={<Statistics />} />
      <MyMeetings />
      <Divider sx={{ my: 2 }} />
      <Campaigns />
      <Divider sx={{ my: 2 }} />
      <Overdue />
    </>
  )
}

export default Dashboard
