import apiSlice from '../api/slice'
import { TAGS } from '../../constants/tags'

const contactsSlice = apiSlice.injectEndpoints({
  name: 'contacts',
  endpoints: (builder) => ({
    getContacts: builder.query({
      query: ({ prospectId }) => ({ url: `/v2/contacts/prospects/${prospectId}` }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.CONTACTS],
    }),
    updateContact: builder.mutation({
      query: ({ contactId, campaignId, contact }) => ({
        url: `/v2/contacts/${contactId}/campaigns/${campaignId}`,
        method: 'PUT',
        body: contact,
      }),
      invalidatesTags: [TAGS.CONTACTS],
    }),
    updateDecisionMaker: builder.mutation({
      query: ({ prospectId, campaignId, contactId }) => ({
        url: `/v2/prospects/${prospectId}/campaigns/${campaignId}/dm/${contactId}`,
        method: 'PUT',
      }),
      invalidatesTags: [TAGS.CONTACTS, TAGS.PROSPECT],
    }),
    addContact: builder.mutation({
      query: ({ prospectId, campaignId, contact }) => ({
        url: `/v2/contacts/prospects/${prospectId}/campaigns/${campaignId}`,
        method: 'POST',
        body: contact,
      }),
      invalidatesTags: [TAGS.CONTACTS],
    }),
  }),
})

export const { useGetContactsQuery, useUpdateContactMutation, useUpdateDecisionMakerMutation, useAddContactMutation } =
  contactsSlice

export default contactsSlice
