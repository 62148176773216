import ProspectContextProvider from './components/prospect/provider'
import ProspectWithBlocker from './components/prospect/blocker'

const ProspectWithBlockerContext = () => {
  return (
    <ProspectContextProvider>
      <ProspectWithBlocker />
    </ProspectContextProvider>
  )
}

export default ProspectWithBlockerContext
