import apiSlice from '../../services/api/slice'

const searchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({ searchTerm }) => ({
        url: `/v2/search/${searchTerm.replace(/\/|\\|\?|&/g, '')}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
    }),
  }),
})

export const { useSearchQuery } = searchSlice
export default searchSlice
