import { Field } from 'formik'
import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'

const PhoneNumberInput = (props) => {
  const { name, width, ...rest } = props
  return (
    <Field name={name} {...rest}>
      {({ field, meta }) => {
        return (
          <InputMask mask="+1 (999) 999-9999" value={field.value} onChange={field.onChange} onBlur={field.onBlur}>
            {() => (
              <TextField
                error={!!meta.touched && !!meta.error}
                helperText={meta.touched && meta.error ? meta.error : ''}
                sx={{ my: 1, width }}
                {...rest}
                {...field}
                size="small"
              />
            )}
          </InputMask>
        )
      }}
    </Field>
  )
}

export default PhoneNumberInput
