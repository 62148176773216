import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'eventDrawer',
  initialState: {
    isOpen: false,
    currentProspect: {},
    currentCampaign: {},
  },
  reducers: {
    close: (state) => {
      state.isOpen = false
    },
    toggle: (state) => {
      state.isOpen = !state.isOpen
    },
    setCurrentProspect: (state, action) => {
      state.currentProspect = action.payload
    },
    setCurrentCampaign: (state, action) => {
      state.currentCampaign = action.payload
    },
  },
})

export const { toggle, setCurrentProspect, setCurrentCampaign } = slice.actions
export default slice
