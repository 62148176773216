import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import MuiBox from '@mui/material/Box'
import MuiContainer from '@mui/material/Container'
import MuiTypography from '@mui/material/Typography'

import { logout } from '../../services/auth/slice'
import apiSlice from '../../services/api/slice'

const Logout = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(logout())
    dispatch(apiSlice.util.resetApiState())
    navigate('/')
  }, [dispatch, navigate])

  return (
    <MuiContainer maxWidth="xs">
      <MuiBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 8,
          alignItems: 'center',
        }}
      >
        <MuiTypography component="h1" variant="h5">
          You have been logged out.
        </MuiTypography>
      </MuiBox>
    </MuiContainer>
  )
}

export default Logout
