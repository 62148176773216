import React from 'react'
import { Box, Typography } from '@mui/material'

const DailyChartLabels = () => {
  // Generate border spans programmatically
  const borderSpans = Array.from({ length: 9 }).map((_, index) => (
    <Box key={index} component="span" sx={{ borderRight: '1px solid grey', height: '12px', width: '10%' }} />
  ))

  // Generate time labels programmatically
  const timeLabels = Array.from({ length: 9 }, (_, i) => 9 + i).map((time, index) => (
    <Box key={index} component="span" sx={{ height: '12px', width: '10%' }}>
      <Typography width="100%" align="center">
        {`${time}:00`}
      </Typography>
    </Box>
  ))

  return (
    <Box width="100%" display="flex">
      <Box sx={{ position: 'relative', height: '24px', width: '11%', display: 'flex' }} />
      <Box width="75%">
        <Box sx={{ position: 'relative', height: '12px', width: '100%', display: 'flex' }}>
          {borderSpans}
          <Box component="span" sx={{ height: '12px', width: '10%' }} />
        </Box>
        <Box sx={{ position: 'relative', height: '12px', width: '100%', display: 'flex' }}>
          <Box component="span" sx={{ height: '12px', width: '5%' }} />
          {timeLabels}
          <Box component="span" sx={{ height: '12px', width: '5%' }} />
        </Box>
      </Box>
      <Box sx={{ position: 'relative', height: '12px', width: '14%', display: 'flex' }} />
    </Box>
  )
}

export default DailyChartLabels
