import apiSlice from '../../services/api/slice'
import { TAGS } from '../../constants/tags'

const campaignsSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyCampaign: builder.query({
      query: ({ campaignId }) => ({
        url: `/v2/campaigns/${campaignId}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.CAMPAIGN],
    }),
    getMyCampaigns: builder.query({
      query: () => ({ url: `/v2/campaigns`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.CAMPAIGNS],
    }),
  }),
})

export const { useGetMyCampaignQuery, useGetMyCampaignsQuery } = campaignsSlice
export default campaignsSlice
