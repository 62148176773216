import apiSlice from '../api/slice'
import { TAGS } from '../../constants/tags'

const fieldsSlice = apiSlice.injectEndpoints({
  name: 'fields',
  endpoints: (builder) => ({
    getFields: builder.query({
      query: (entity) => ({ url: `/v2/fields/${entity}`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.FIELDS_ENTITY],
    }),
    getCampaignFields: builder.query({
      query: ({ campaignId }) => ({ url: `/v2/fields/campaigns/${campaignId}`, method: 'GET' }),
      transformResponse: (response) => response.data,
      providesTags: [TAGS.FIELDS_CAMPAIGN],
    }),
  }),
})

export const { useGetFieldsQuery, useGetCampaignFieldsQuery } = fieldsSlice

export default fieldsSlice
