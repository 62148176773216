import { createSlice } from '@reduxjs/toolkit'
import login from './cognito/login'
import logout from './cognito/logout'
import refresh from './cognito/refresh'
import impersonate from './cognito/impersonate'
import unimpersonate from './cognito/unimpersonate'

const initialState = {
  isLoading: false,
  user: null,
  impersonation: null,
  tokens: null,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
      state.user = null
      state.impersonation = null
      state.tokens = null
      state.error = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload.user
      state.impersonation = null
      state.tokens = action.payload.tokens
      state.error = null
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.user = null
      state.impersonation = null
      state.tokens = null
      state.error = action.error.message
    })
    builder.addCase(logout.fulfilled, (state) => {
      state.isLoading = false
      state.user = null
      state.impersonation = null
      state.tokens = null
      state.error = null
    })
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.isLoading = false
      state.user = action.payload.user
      state.tokens = { ...state.tokens, ...action.payload.tokens }
      state.error = null
    })
    builder.addCase(impersonate.pending, (state) => {
      state.isLoading = true
      state.impersonation = null
      state.error = null
    })
    builder.addCase(impersonate.fulfilled, (state, action) => {
      state.isLoading = false
      state.impersonation = { ...action.payload }
      state.error = null
    })
    builder.addCase(impersonate.rejected, (state, action) => {
      state.isLoading = false
      state.impersonation = null
      state.error = action.error.message
    })
    builder.addCase(unimpersonate.pending, (state) => {
      state.isLoading = true
      state.impersonation = null
      state.error = null
    })
    builder.addCase(unimpersonate.fulfilled, (state) => {
      state.isLoading = false
      state.impersonation = null
      state.error = null
    })
    builder.addCase(unimpersonate.rejected, (state, action) => {
      state.isLoading = false
      state.impersonation = null
      state.error = action.error.message
    })
  },
})

export default authSlice
export { login, logout, refresh, impersonate, unimpersonate }
