import Section from './section'

const Sections = ({ client, campaign, prospect }) => {
  return campaign.layout?.sections
    ?.slice()
    ?.sort((a, b) => a.Priority - b.Priority)
    ?.map((section) => (
      <Section
        key={section['Section Name']}
        client={client}
        section={section}
        campaign={campaign}
        prospect={prospect}
      />
    ))
}

export default Sections
