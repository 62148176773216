import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  GetUserCommand,
} from '@aws-sdk/client-cognito-identity-provider'

import LogRocket from 'logrocket'
import AppConfig from '../../../config'

const client = new CognitoIdentityProviderClient({ region: AppConfig.aws.region })

const refresh = createAsyncThunk('auth/refresh', async (_, { getState }) => {
  const { auth } = getState().persisted

  try {
    const params = {
      AuthFlow: 'REFRESH_TOKEN',
      ClientId: AppConfig.aws.cognito.clientId,
      AuthParameters: {
        REFRESH_TOKEN: auth.tokens.RefreshToken,
      },
    }

    const authResponse = await client.send(new InitiateAuthCommand(params))

    if (authResponse?.AuthenticationResult?.AccessToken) {
      const userParams = {
        AccessToken: authResponse.AuthenticationResult.AccessToken,
      }

      const userResponse = await client.send(new GetUserCommand(userParams))
      const user = userResponse.UserAttributes.reduce((acc, attr) => {
        acc[attr.Name] = attr.Value
        return acc
      }, {})

      const attr = {
        version: AppConfig.app.version,
      }

      if (AppConfig.logRocket.appId) LogRocket.identify(user.email, attr)

      return {
        tokens: authResponse.AuthenticationResult,
        user,
      }
    } else {
      throw new Error('Authentication failed')
    }
  } catch (e) {
    console.error('Error:', e)
    throw e
  }
})

export default refresh
