import { useEffect, useMemo, useContext, useCallback } from 'react'
import { useBlocker } from 'react-router-dom'
import ConfirmNavigation from './confirmNavigation'
import Prospect from './prospect'
import ProspectContext from './context'

const ProspectWithBlocker = () => {
  const { data } = useContext(ProspectContext)

  const hasUnsavedChanges = useMemo(() => Object.values(data).some((value) => value), [data])

  const shouldBlock = useCallback(
    ({ currentLocation, nextLocation }) => {
      return hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname
    },
    [hasUnsavedChanges]
  )

  const blocker = useBlocker(shouldBlock, hasUnsavedChanges)

  useEffect(() => {
    if (!hasUnsavedChanges && blocker.state === 'blocked') {
      blocker.reset()
    }
  }, [blocker, hasUnsavedChanges])

  return (
    <>
      <Prospect />
      <ConfirmNavigation blocker={blocker} />
    </>
  )
}

export default ProspectWithBlocker
